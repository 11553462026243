import { useEffect, useState } from "react";
import apiConfig from "../apiConfig.json";
import logo from '../Images/login-logo.svg';


const LogInAdmin = ({onLogin}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const clearUsernameError = () => {
    setUsernameError('');
    setLoginError(false);
  };

  const clearPasswordError = () => {
    setPasswordError('');
    setLoginError(false);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    validateUsername();
    validatePassword();

    if(!usernameError && !passwordError) {
      fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_LOGIN}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username, password
        })
      })
      .then(response => {
        if(response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok');
      })
      .then(data => {
        const accessToken = data.accessToken;
        localStorage.setItem('accessToken', accessToken);
        onLogin(accessToken);
        setLoginError(false);
        setUsernameError('');
        setPasswordError('');
      })
      .catch(error => {
        console.error('Error during login:', error);
        setLoginError(true);
      });
    } else {
      setLoginError(false);
    }
  };

  const handleUsernameChange = (value) => {
    setUsername(value);
    clearUsernameError();
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    clearPasswordError();
  };

  const validateUsername = () => {
    if (!username.match(/^[A-ZА-Я][a-zа-я]+[A-ZА-Я]{2}$/)) {
      setUsernameError('*Неправильный формат имени');
    } else {
      setUsernameError('');
    }
  };

  const validatePassword = () => {
    if (password.length < 6) {
      setPasswordError('*Пароль должен содержать минимум 6 символов');
    } else {
      setPasswordError('');
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      onLogin(storedToken);
    }
  }, [onLogin]);



  return(
    <>
      <div className="login-wrapper">
        <div className="form-wrapper">
          <img className="form-wrapper__logo" src={logo} alt="logo"/>
          <form onSubmit={(event) => handleLogin(event)} className={isMobile ? 'login-form login-form-mobile' : ' login-form login-form-desktop'}>
              <input type="text" placeholder="Имя" value={username} onChange={(e) => handleUsernameChange(e.target.value)} onTouchStart={(e) => e.target.focus()} className={usernameError ? 'input-error' : 'input-validated'}/>
              {usernameError && <p className="error-message">{usernameError}</p>}
              <input type="password" placeholder="Пароль" value={password} onChange={(e) => handlePasswordChange(e.target.value)} onTouchStart={(e) => e.target.focus()} className={passwordError ? 'input-error' : 'input-validated'}/>
              {passwordError && <p className="error-message">{passwordError}</p>}
            <button type="submit">Войти</button>
            {!usernameError && !passwordError && loginError && <p className='error-visible'>Пользователь не найден</p>}
          </form>
        </div>
      </div>
    </>
  )
}
export default LogInAdmin;
