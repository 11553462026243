import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { QAForShifts, QAForAddition } from "./questionsAndAnswers"
import NavigationMenu from "../../Components/NavigationMenu"
import "./FAQ.css"

const MainQuestionsPage = () => {
  const [category, setCategory] = useState(localStorage.getItem("category") || 'table')
  const [isAnswerExpanded, setIsAnswerExpanded] = useState({})
  const [isNavigaitionExpanded, setIsNavigaitionExpanded] = useState(false);
  const contentHeightRefs = useRef({})

  useEffect(() => {
    localStorage.setItem("category", category);
    setIsAnswerExpanded({})
    contentHeightRefs.current = {}
  }, [category]);

  const currentQuestions = (category) => {
    switch (category) {
      case 'table' :
        return QAForShifts
      case 'addition':
        return QAForAddition
      default:
        return QAForShifts
    }
  }
  
  const linkToGo = (category) => {
    switch (category) {
      case 'table' :
        return '/load-data-table'
      case 'addition':
        return '/addition-user'
      default:
        return '/load-data-table'
    }
  }
  
  const renderImagesInAnswer = (answer, images) => {
    if(!answer) { return }
    return answer.replace(/\[(\w+)\]/g, (match, imgKey) => {
      const imgSrc = images[imgKey]
      return imgSrc ? `<img src="${process.env.PUBLIC_URL}/FAQ/${imgSrc}" alt="" />` : match
    })
  }

  const onExpandAnswer = (index) => {
    setIsAnswerExpanded(prevList => {
      if(!prevList[index]) {
        return {
          ...prevList,
          [index]: true
        }
      } else {
        return {
          ...prevList,
          [index]: false
        }
      }
    })
  }

  const onExpandNavigation = () => {
    setIsNavigaitionExpanded(p => !p)
  }

  const onChangeCategory = (category) => {
    setCategory(category)
    setIsNavigaitionExpanded(false)
  }

  return (
    <div className="body-wrapper">
      <div className="page-flex-wrapper">
        <NavigationMenu />
        <div className="faq-main-container">
          <header className="faq-header">
            <nav className='faq-nav'>
            <h1>Помощь</h1>
            <div className="faq-nav__container">
              <div className='faq-nav__list_header' onClick={onExpandNavigation}>
                <div className={`faq-nav__list_img navigation-list-${category}`}></div>
              </div>
              <ul className='faq-nav__list' style={{display: isNavigaitionExpanded ? 'block' : 'none'}}>
                <li onClick={() => onChangeCategory('table')}
                style={{display: category !== 'table' ? 'block' : 'none'}}
                className='faq-nav__item'>
                  <div className='faq-nav__list_img navigation-list-table'>
                  </div>
                </li>
                <li
                onClick={() => onChangeCategory('addition')}
                style={{display: category !== 'addition' ? 'block' : 'none'}}
                className='faq-nav__item'>
                  <div className='faq-nav__list_img navigation-list-addition'>
                  </div>
                </li>
                {/* <li
                onClick={() => onChangeCategory('tracker')}
                style={{display: category !== 'tracker' ? 'block' : 'none'}}
                className='faq-nav__item'>
                  <div className='faq-nav__list_img navigation-list-tracker' />
                </li>
                <li
                onClick={() => onChangeCategory('service')}
                style={{display: category !== 'service' ? 'block' : 'none'}}
                className='faq-nav__item'>
                  <div className='faq-nav__list_img navigation-list-service' />
                </li> */}
              </ul>
            </div>
            </nav>

            <button className="close-btn"> 
              <Link style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 'auto',
                color: 'black',
                textDecoration: 'none',
                width: '100%',
                height: '100%',
                borderRadius:'30px'
              }}
               to={linkToGo(category)}>
              </Link>
            </button>
          
          </header>
          
          <main className="faq-main">
            <div className="faq-main__wrapper">
            {currentQuestions(category).map((questionData, index) => {
              if (questionData.header) {
                return (
                  <div className="faq-main__subtitle">{questionData.header}</div>
                )
              } else {
                  return(
                    <div key={index} className="faq-main__item">
                    {/* <div className="">{questionData.header}</div> */}
                    <div className="faq-main__item_question_wrapper">
                      <div className="faq-main__item_question" onClick={() => onExpandAnswer(index)}>{questionData.question}</div>
                      <button
                      className={`open-btn ${isAnswerExpanded[index] ? 'open' : ''}`}
                      onClick={() => onExpandAnswer(index)} />
                    </div>
                    <div 
                      className="faq-main__item_answer"
                      ref={(item) => contentHeightRefs.current[index] = item}
                      style = {{
                        maxHeight: isAnswerExpanded[index]
                        ? `${contentHeightRefs.current[index]?.scrollHeight || 0}px`
                        : "0px",
                        overflow: "hidden",
                        transition: "max-height 0.5s ease",
                      }} >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: renderImagesInAnswer(questionData.answer, questionData.images)
                        }}
                        />
                    </div>
                  </div>
                )
              }
            })}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default MainQuestionsPage