import './modalDelete.css';

const ModalDelete = (props) => {

  const message = props.message;
  const action = props.action;
  const onClose = props.onClose;

  return(
    <>
      <div className="modal-delete-container">
        <div className="modal-delete-wrapper">
          <p>{message}</p>
          <section className='modal-delete-buttons-block'>
            <button className='button-modal-delete button-reverse-deletion' onClick={() => onClose()}>Отменить</button>
            <button className='button-modal-delete button-deletion'>{action}</button>
          </section>
        </div>
      </div>
    </>
  )
}
export default ModalDelete;
