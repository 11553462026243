import { useState, useRef, useEffect } from "react";
import './modals.css'
const ModalNote = (props) => {
  const {note, modalRef, type = "regular", onClose, ...data} = props;
  const copyValueRefs = useRef({});
  const [copiedStates, setCopiedStates] = useState({});

  const changeColor = (type) => {
    switch (type) {
      case "regular":
        return "#ffffff";
      case "success":
        return "#59a32f";
      case "error":
        return "#a7434f";
      default:
        return "#ffffff";
    }

  }
  const handleCopyText = async (inputValue, key) => {
    const copiedText = inputValue.value;
    try {
      await navigator.clipboard.writeText(copiedText);
      setCopiedStates((prevStates) => ({
      ...prevStates,
        [key]: true
      }));
      // console.log(copyValueRefs)
      setTimeout(() => {
        setCopiedStates((prevStates) => ({...prevStates, [key]: false}));
    }, 2000);
    } catch (error) {
      console.log("failed triyng to copy text: ", error);
    }
  };


  useEffect(() => {
    const handleCloseFilterByEscape = (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    };
    document.body.addEventListener('keydown', handleCloseFilterByEscape);

    return () => {
      document.body.removeEventListener('keydown', handleCloseFilterByEscape);
    };
  }, [onClose]);

  return (
    <div className="modal-window modal-window_note" ref={modalRef}>
    <div className="modal-window__wrapper modal-window_note__wrapper">
      <button className="modal-window__button-close" onClick={() => onClose()}/>
      <p className="modal-window_note__note" style = {{color: changeColor(type)}}>{note}</p>
      <section className="modal-window_note__section">
        {Object.keys(data).map(key => (
          <>
            <label key={key} className='modal-window_note__label'>
              <input
                ref={(el) => (copyValueRefs.current[key] = el)}
                type="text" readOnly={true}
                value={data[key]}
                className='modal-window_note__input'
              />
              <button onClick={() => handleCopyText(copyValueRefs.current[key], key)} className="modal-window__copy-button" />
              <div className={`modal-window__copy-note ${copiedStates[key] ? 'copied' : ''}`}>Скопировано!</div>
            </label>
          </>
        ))}
      </section>
    </div>
   </div>
  )
}

export default ModalNote;
