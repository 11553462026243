const QAForShifts = [
  {
    question: 'Как применить фильтр?',
    answer: `Для начала нужно нажать кнопку [img1] после этого выбрать из выпадающего списка выбрать раздел фильтра [img2] и после выбрать какой конкретно фильтр нужно добавить [img3] вписать соответствующую фильтру информацию [img4] и нажать кнопку “применить”. Если такой информации нет, фильтр не применится и появится ошибка [img5].`,
    images: {
      img1: 'shifts/question1Image1.jpg',
      img2: 'shifts/question1Image2.jpg',
      img3: 'shifts/question1Image3.jpg',
      img4: 'shifts/question1Image4.jpg',
      img5: 'shifts/question1Image5.jpg'
    }
  },
  {
    question: 'Как удалить фильтр?',
    answer: `Для удаления фильтра необходимо нажать на крестик в плашке с соответствующим примененным фильтром [img1].`,
    images: {
      img1:  'shifts/question2Image1.jpg'
    }
  },
  {
    question: 'Как поменять количество отображаемых смен?',
    answer: `Для того чтобы изменить количество отображаемых смен необходимо нажать на кнопку [img1], находящуюся под таблицей, и выбрать из выпадающего списка необходимое количество отображаемых смен [img2].`,
    images: {
      img1:  'shifts/question3Image1.jpg',
      img2:  'shifts/question3Image2.jpg'
    }
  },
  {
    question: 'Как посмотреть фотографии смены, напряжение и маршрут?',
    answer: `Для того чтобы просмотреть фото необходимо нажать на иконку с загруженной фотографии в смене [img1], после этого появится всплывающее окно со всеми фотографиями, которые можно будет листать, нажимая на стрелочки. Если фотографии не загружены, вместо фотографии будет отображаться иконка [img2].
      Для того, чтобы посмотреть маршрут и напряжение, необходимо нажать на иконки [img3](маршрут) и [img4] (напряжение), если вместо иконок на их месте находится прочерк [img5], то такой информации нет и просмотреть её нельзя.`,
    images: {
      img1:  'shifts/question4Image1.jpg',
      img2:  'shifts/question4Image2.jpg',
      img3:  'shifts/question4Image3.jpg',
      img4:  'shifts/question4Image4.jpg',
      img5:  'shifts/question4Image5.jpg'
    }
  },
  {
    question: 'Где находится информация об остановках и какие функции в ней есть?',
    answer: `Информацию об остановках можно посмотреть нажав на кнопку [img1] в необходимой смене, после этого появится всплывающее окно с информациейо рейсах, остановках и картой. На карте отображаются такие параметры как: частота остановок, точки остановок и рейсы, каждый из параметров можно не отображать, нажав на кнопку [img2], после нажатия исчезнет галочка с кнопки и параметр перестанет отображаться.
Частота остановок отображается в виде желтых квадратиков [img3], чем ярче цвет, тем чаще в этом месте на карте останавливаются сотрудники. Точки остановок отбражаются в виде иконок местоположения [img4], нажав на них можно увидеть подробную информацию об остановке [img5].  Рейсы отображаются в виде линий с точками, каждая точка это начало маршрута следующего рейса [img6].`,
    images: {
      img1:  'shifts/question5Image1.jpg',
      img2:  'shifts/question5Image2.jpg',
      img3:  'shifts/question5Image3.jpg',
      img4:  'shifts/question5Image4.jpg',
      img5:  'shifts/question5Image5.jpg',
      img6:  'shifts/question5Image6.jpg'
    }
  },
  {
    question: 'Как посмотреть подробную информацию о сотруднике и какие функции в ней есть?',
    answer: `Для того чтобы посмотреть подробную информацию о сотрудниках необходимо нажать на ФИО сотрудника, информацию о котором вы хотите просмотреть, в таблице смен [img1]. После этого появится всплывающее окно с информацией о сотруднике. Для того, чтобы редактировать информацию о сотруднике необходимо нажать на кнопку [img2] после чего поля которые можно изменять станут серыми, после нажатия в них можно будет вписать новую информацию [img3]. Также можно добавить или удалить IMEI, для удаления нужно просто нажать на кнопку “удалить” [img4], для того чтобы добавить - нажать на кнопку “добавить”, вписать IMEI и нажатиь кнопку добавить ещё раз [img5].
    НЕ ЗАБУДЬТЕ ПОСЛЕ ВСЕХ ИЗМЕНЕНИЙ НАЖАТЬ НА КНОПКУ “СОХРАНИТЬ” в правом нижнем углу [img6]. Помимо редактирования вы также можете удалить сотрудника, нажав на кнопку [img7] и подтведив действие нажав кнопку “удалить” [img8] в окне подтверждения.
    Также возле текущей и предыдущих смен есть кнопка [img9], которая переносит на эту же смену в таблице смен [img10].`,
    images: {
      img1:  'shifts/question6Image1.jpg',
      img2:  'shifts/question6Image2.jpg',
      img3:  'shifts/question6Image3.jpg',
      img4:  'shifts/question6Image4.jpg',
      img5:  'shifts/question6Image5.jpg',
      img6:  'shifts/question6Image6.jpg',
      img7:  'shifts/question6Image7.jpg',
      img8:  'shifts/question6Image8.jpg',
      img9:  'shifts/question6Image9.jpg',
      img10:  'shifts/question6Image10.jpg'
    }
  },
  {
    question: 'Как посмотреть подробную информацию об оборудовании и какие функции в ней есть?',
    answer: `Для того чтобы посмотреть подробную информацию о сотрудниках необходимо нажать на иконку оборудования, информацию о котором вы хотите просмотреть, в таблице смен [img1]. После этого появится всплывающее окно с информацией об оборудовании. Для того, чтобы редактировать информацию о сотруднике необходимо нажать на кнопку [img2] после чего поля которые можно изменять станут серыми, после нажатия в них можно будет вписать новую информацию [img3].  Также можно добавить или удалить датчик, для удаления нужно просто нажать на кнопку “удалить” [img4] , для того чтобы добавить - нажать на кнопку “добавить”, вписать датчик и нажатиь кнопку добавить ещё раз [img5]. НЕ ЗАБУДЬТЕ ПОСЛЕ ВСЕХ ИЗМЕНЕНИЙ НАЖАТЬ НА КНОПКУ “СОХРАНИТЬ” в правом нижнем углу [img6].
Помимо редактирования вы также можете удалить оборудование, нажав на кнопку [img7] и подтведив действие нажав кнопку “удалить” [img8] в окне подтверждения.
Также возле текущей и предыдущих смен есть кнопка [img9], которая переносит на эту же смену в таблице смен [img10].`,
    images: {
      img1:  'shifts/question7Image1.jpg',
      img2:  'shifts/question7Image2.jpg',
      img3:  'shifts/question7Image3.jpg',
      img4:  'shifts/question7Image4.jpg',
      img5:  'shifts/question7Image5.jpg',
      img6:  'shifts/question7Image6.jpg',
      img7:  'shifts/question7Image7.jpg',
      img8:  'shifts/question7Image8.jpg',
      img9:  'shifts/question7Image9.jpg',
      img10:  'shifts/question7Image10.jpg'
    }
  }
]

const QAForAddition = [
  { header: 'Оборудование' },
  {
    question: 'Как перейти в раздел оборудования?',
    answer: `Для того чтобы перейти в раздел оборудования необходимо нажать на кнопку-переключатель со стороны с надписью “оборудование”, после нажатия вы попадёте на страницу со списком оборудования, кнопка со стороны оборудования станет активной (станет тёмная) [img1].`,
    images: {
      img1:  'addition/question1Image1.jpg'
    }
  },
  {
    question: 'Как добавить новое оборудование?',
    answer: `Для того чтобы добавить новое оборудование необходимо нажать на кнопку [img1] , после этого появится окно ввода информации о новом оборудовании, необходимо ввести в поля необходимую информацию и выбрать тип оборудования [img2] . После заполнения, необходимо нажать на кнопку [img3] .`,
    images: {
      img1:  'addition/question2Image1.jpg',
      img2:  'addition/question2Image2.jpg',
      img3:  'addition/question2Image3.jpg'
    }
  },
  {
    question: 'Как применить фильтр?',
    answer: `Для начала нужно нажать кнопку [img1], после этого выбрать из выпадающего списка выбрать какой фильтр вы хотите добавить [img2], вписать соответствующую фильтру информацию [img3] и нажать кнопку “приминить”. Если такой информации нет, фильтр не примениться и  появится ошибка [img4].`,
    images: {
      img1:  'addition/question3Image1.jpg',
      img2:  'addition/question3Image2.jpg',
      img3:  'adition/question3Image3.jpg',
      img4:  'adition/question3Image3.jpg'
    }
  },
  {
    question: 'Как удалить фильтр?',
    answer: `Для удаления фильтра необходимо нажать на крестик в плашке с соответствующим примененным фильтром [img1].`,
    images: {
      img1:  'addition/question4Image1.jpg'
    }
  },
  {
    question: 'Как узнать какое оборудование на смене?',
    answer: `У оборудования, находящегося на смене слева от иконки с типом оборудования есть зелёный кружок [img1].`,
    images: {
      img1:  'addition/question5Image1.jpg'
    }
  },
  {
    question: 'Как посмотреть подробную информацию об оборудовании?',
    answer: `Для того, чтобы посмотреть подробную информацию о сотрудниках, необходимо нажать на иконку оборудования, информацию о котором вы хотите просмотреть, в таблице смен [img1]. После этого появится всплывающее окно с информацией об оборудовании. Для того, чтобы редактировать информацию о сотруднике необходимо нажать на кнопку [img2] после чего поля которые можно изменять станут серыми, после нажатия в них можно будет вписать новую информацию [img3].  Также можно добавить или удалить датчик, для удаления нужно просто нажать на кнопку “удалить” [img4], для того чтобы добавить - нажать на кнопку “добавить”, вписать датчик и нажатиь кнопку добавить ещё раз [img5]. НЕ ЗАБУДЬТЕ ПОСЛЕ ВСЕХ ИЗМЕНЕНИЙ НАЖАТЬ НА КНОПКУ “СОХРАНИТЬ” в правом нижнем углу [img6].
Помимо редактирования вы также можете удалить оборудование, нажав на кнопку [img7] и подтведив действие нажав кнопку “удалить” [img8] в окне подтверждения.
Также возле текущей и предыдущих смен есть кнопка [img9] , которая переносит на эту же смену в таблице смен [img10] .`,
    images: {
      img1:  'addition/question6Image1.jpg',
      img2:  'addition/question6Image2.jpg',
      img3:  'addition/question6Image3.jpg',
      img4:  'addition/question6Image4.jpg',
      img5:  'addition/question6Image5.jpg',
      img6:  'addition/question6Image6.jpg',
      img7:  'addition/question6Image7.jpg',
      img8:  'addition/question6Image8.jpg',
      img9:  'addition/question6Image9.jpg',
      img10: 'addition/question6Image10.jpg'
    }
  },
  { header: 'Сотрудники' },
  {
    question: 'Как перейти в раздел сотрудников?',
    answer: `Для того чтобы перейти в раздел сотрудников необходимо нажать на кнопку-переключатель со стороны с надписью “сотрудники”, после нажатия вы попадёте на страницу со списком сотрудников, кнопка со стороны сотрудников станет активной (станет тёмной) [img1] .`,
    images: {
      img1:  'addition/question7Image1.jpg'
    }
  },
  {
    question: 'Как добавить нового сотрудника?',
    answer: `Для того, чтобы добавить новое оборудование, необходимо нажать на кнопку [img1], после этого появится окно ввода информации о новом сотруднике. Необходимо ввести в поля нужную информацию, ввести или сгенерировать пароль (нажав на кнопку “сгенерировать”) от 10 до 12 символов [img2], выбрать должность - выбранная  должность будет также становиться темной - [img3]. Если выбрана должность “рабочий” , нужно также выбрать оборудование, на которм он работает (можно несколько) [img4]. После заполнения необходимо нажать на кнопку [img5].`,
    images: {
      img1:  'addition/question8Image1.jpg',
      img2:  'addition/question8Image2.jpg',
      img3:  'addition/question8Image3.jpg',
      img4:  'addition/question8Image4.jpg',
      img5:  'addition/question8Image5.jpg'
    }
  },
  {
    question: 'Как применить фильтр?',
    answer: `Для начала нужно нажать кнопку [img1] после этого выбрать из выпадающего списка выбрать какой фильтр вы хотите добавить [img2] , вписать соответствующую фильтру информацию [img3] и нажать кнопку “применить”. Если такой информации нет, фильтр не примениться и появится ошибка [img4] .`,
    images: {
      img1:  'addition/question9Image1.jpg',
      img2:  'addition/question9Image2.jpg',
      img3:  'addition/question9Image3.jpg',
      img4:  'addition/question9Image4.jpg'
    }
  },
  {
    question: 'Как удалить фильтр?',
    answer: `Для удаления фильтра необходимо нажать на крестик в плашке с соответствующим примененным фильтром [img1].`,
    images: {
      img1:  'addition/question10Image1.jpg'
    }
  },
  {
    question: 'Как посмотреть подробную информацию о сотруднике и какие функции в ней есть?',
    answer: `Для того чтобы посмотреть подробную информацию о сотрудниках необходимо нажать на ФИО сотрудника, информацию о котором вы хотите просмотреть, в таблице смен [img1]. После этого появится всплывающее окно с информацией о сотруднике. Для того, чтобы редактировать информацию о сотруднике необходимо нажать на кнопку [img2] после чего поля которые можно изменять станут серыми, после нажатия в них можно будет вписать новую информацию [img3]. Также можно добавить или удалить IMEI, для удаления нужно просто нажать на кнопку “удалить” [img4], для того чтобы добавить - нажать на кнопку “добавить”, вписать IMEI и нажатиь кнопку добавить ещё раз [img5].
    НЕ ЗАБУДЬТЕ ПОСЛЕ ВСЕХ ИЗМЕНЕНИЙ НАЖАТЬ НА КНОПКУ “СОХРАНИТЬ” в правом нижнем углу [img6]. Помимо редактирования вы также можете удалить сотрудника, нажав на кнопку [img7] и подтведив действие нажав кнопку “удалить” [img8] в окне подтверждения.
    Также возле текущей и предыдущих смен есть кнопка [img9], которая переносит на эту же смену в таблице смен [img10].`,
    images: {
      img1:  'addition/question11Image1.jpg',
      img2:  'addition/question11Image2.jpg',
      img3:  'addition/question11Image3.jpg',
      img4:  'addition/question11Image4.jpg',
      img5:  'addition/question11Image5.jpg',
      img6:  'addition/question11Image6.jpg',
      img7:  'addition/question11Image7.jpg',
      img8:  'addition/question11Image8.jpg',
      img9:  'addition/question11Image9.jpg',
      img10:  'addition/question11Image10.jpg'
    }
  },
  {
    question: 'Что означает иконка телефона рядом с сотрудником?',
    answer: `Иконка телефона у сотрудника означает наличие IMEI.`
  }
]

export { QAForShifts, QAForAddition }