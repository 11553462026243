
import { createPortal } from 'react-dom';
import '../taskTracker.css';

const DetailedCardModal = ({color, isOpened, onClose}) => {
  
  if(!isOpened) return null;
  
  const placeModalElem = document.querySelector('.tracker-main__wrapper');

  return (
    <>
      {createPortal (

      <div className="card-modal__container">
        <div className="card-modal__wrapper">
          <section className='card__info-general modal-card__info-general'>
            <div className="card__info-general-direction">
              <span className="info-direction" style={{backgroundColor: `${color}`}}>ДН-1</span>
              <span className="info-dates">21.07.2024 - 31.07.2024</span>
            </div>
            <div className="task-card__actions">
              <button className="edit-card__button card-button"></button>
              <button className="delete-card__button card-button"></button>
            </div>
          </section>

          <p className="card__info-description">
            Разработка грунта ковшом
          </p>

          <section className="card__info-other">
            <ul className="info-other-staff__list">
              <li className="info-block staff-list__item">
                <img src="#" alt="" className="staff-item__avatar" />
                <span className="staff-item__name">Иванов Иван Иванович</span>
              </li>
              <li className="info-block staff-list__item">
                <img src="#" alt="" className="staff-item__avatar" />
                <span className="staff-item__name">Иванов Иван Иванович</span>
              </li>
              <li className="info-block staff-list__item">
                <img src="#" alt="" className="staff-item__avatar" />
                <span className="staff-item__name">Иванов Иван Иванович</span>
              </li>
              <li className="info-block staff-list__item">
                <img src="#" alt="" className="staff-item__avatar" />
                <span className="staff-item__name">Иванов Иван Иванович</span>
              </li>
              <li className="info-block staff-list__item">
                <img src="#" alt="" className="staff-item__avatar" />
                <span className="staff-item__name">Иванов Иван Иванович</span>
              </li>
            </ul>
              <span className="info-location">
                Мустах
              </span>
          </section>
      
          <section className="card__info-about-task">
            <p className="info-about__task-type">
              Тип задачи: Какой-то тип задачи
            </p>
            <p className="info-about__task-description">
            Какое-то описание задачи, очень подробное. Какое-то описание задачи, очень подробное. Какое-то описание задачи, очень подробное...
            </p>
          </section>

          <section className="card__info-equipment">
            <div className="info-equipment__equipment info-block">
              1111
            </div>
            <div className="info-equipment__equipment info-block">
              2222
            </div>
            <div className=" info-equipment__equipment info-block">  
              3333
            </div>
          </section>

          <p className="card__info-done-status">
            Выполнено: 30.07.2024
          </p>
          
          <div className="card-modal__goto-actions">
            <button className="tracker-goto-button some-action-one"></button>
            <button className="tracker-goto-button some-action-two"></button>
            <button 
              className="tracker-goto-button tracker-goto-arrow-button rotated-left-top"
              onClick={onClose}>
            </button>
          </div>
        </div>
      </div>,
        placeModalElem
      )}
    </>
  );
};

export default DetailedCardModal;