import './switcherButtons.css';
import {Link} from 'react-router-dom';
import { useEffect, useState } from 'react';
import GotoFAQButton from '../../Components/GotoFAQButton';

const SwitcherButtons = (props) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const currentPage = props.currentPage;
  const onSetCategory = (category) => {
    localStorage.setItem("category", category)
  }
  return(
    <>
      <div style={{position: 'relative'}} className={isMobile ? "switcher-wrapper switcher-wrapper-mobile" : 'switcher-wrapper'}>
        {
          currentPage === 'equip' && (
            <>
            <Link className="link-switch link-switch-users" to='/addition-user'>Сотрудники</Link>
            <Link className="link-switch link-switch-equip link-switch-current" to='/addition-equip'>Оборудование</Link>
            </>
          )
        }
        {
          currentPage === 'users' && (
            <>
            <Link className="link-switch link-switch-users link-switch-current" to='/addition-user'>Сотрудники</Link>
            <Link className="link-switch link-switch-equip" to='/addition-equip'>Оборудование</Link>
            </>
          )
        }
        {isMobile ?
          <GotoFAQButton
          category="addition"
          top="-0.2rem"
          right="-3.3rem"
          width="35px"
          height="35px"
          fontSize="18px"
          />
          :
          <GotoFAQButton
            category="addition"
            top="0.1rem"
            right="-3.5rem"
          />
        }
      </div>
    </>
  )
}
export default SwitcherButtons;
