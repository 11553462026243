import './service.css';
import './areas.css';
import './directions.css';
import { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom';

const DirectionBlock = (props, {accessToken}) => {

  const areasArray = Array.isArray(props.areas) ? props.areas : [];
  const [isAddingDirector, setIsAddingDirector] = useState(false);
  const [newDirectorName, setNewDirectorName] = useState('');
  const [director, setDirector] = useState(props.chef);
  const [areas, setAreas] = useState(areasArray);
  const [isAddingArea, setIsAddingArea] = useState(false);
  const [newAreaName, setNewAreaName] = useState('');
  const inputRef = useRef(null);
  const areaInputRef = useRef(null);
  const directorInputRef = useRef(null);
  const id = props.id;
  const name = props.direction;
  const onDeleteClick = props.onDeleteClick;

  const toggleDirectorInput = () => {
    setIsAddingDirector(!isAddingDirector);
  }

  const handleDirectorInputChange = (event) => {
    setNewDirectorName(event.target.value)
  }

  const handleAddDirector = () => {
    if(newDirectorName) {
      setDirector(newDirectorName);
      setNewDirectorName('');
      setIsAddingDirector(false);
    }
  }

  useEffect(() => {
    if (isAddingDirector && directorInputRef.current) {
      directorInputRef.current.focus();
    }
  }, [isAddingDirector]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddDirector();
    }
  };

  const handleAreaInputChange = (event) => {
    setNewAreaName(event.target.value);
  };

  const handleAddArea = () => {
    if (newAreaName.trim()) {
      const updatedAreas = [...areas, newAreaName];
      // const newPlaces = updatedAreas.map((area, index) => ({
        // name: area,
        // directionId: index
      // }));
      // console.log(newPlaces);
      // const formData = [{name, newPlaces}];
      // fetch(`${apiConfigTest.BASE_URL}${apiConfigTest.ENDPOINTS.POST_DIRECTIONS}`, {
      //   method: "POST",
      //   headers: {
      //     'Authorization' : `Bearer ${accessToken}`,
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(formData)
      // })
      // .then(response => {
      //   if(response.ok) {
      //     console.log('it worked!');
      //     setAreas(updatedAreas);
      //   }
      // })

      setAreas(updatedAreas);
      setNewAreaName('');
      setIsAddingArea(false);
    }
  };

  const handleAreaKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddArea();
    }
  };

  // const handleDeleteDirection = () => {
  //   fetch(`${apiConfigTest.BASE_URL}${apiConfigTest.ENDPOINTS.GET_DIRECTIONS}`, {
  //     method: "POST",
  //     headers: {
  //       'Authorization' : `Bearer ${accessToken}`,
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify()
  //   });

  // }

  useEffect(() => {
    if (isAddingArea && areaInputRef.current) {
      areaInputRef.current.focus();
    }
  }, [isAddingArea]);

  const EQUIPMENT = [
    {
      id: '1111',
      type: 'auto'
    },
    {
      id: '2222',
      type: 'bulldozer'
    },
    {
      id: '3333',
      type: 'grader'
    },
    {
      id: '4444',
      type: 'rink'
    },
    {
      id: '5555',
      type: 'truck'
    },
    {
      id: '6666',
      type: 'truck'
    },
    {
      id: '7777',
      type: 'auto'
    },
    {
      id: '8888',
      type: 'grader'
    },
    {
      id: '9999',
      type: 'bulldozer'
    },
  ]

  const STAFF = [
    {
      name: 'Иванов Иван Иванович',
      role: 'analytic'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'analytic'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee with stuff'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee with stuff'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee with stuff'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee'
    }
  ]

  const getimageForEquipment = (equipmentType) => {
    switch (equipmentType) {
      case 'excavator':
        return require('../../Images/Equipment/excavator_32.png');
      case 'bulldozer':
        return require('../../Images/Equipment/bulldozer_32.png');
      case 'crane':
        return require('../../Images/Equipment/crane_32.png');
      case 'grader':
        return require('../../Images/Equipment/grader_32.png');
      case 'rink':
        return require('../../Images/Equipment/rink_32.png');
      case 'truck':
        return require('../../Images/Equipment/truck_32.png');
      case 'auto':
        return require('../../Images/Equipment/auto_32.png');
      default:
        return require('../../Images/Equipment/excavator_32.png');
    }
  }

  const [staffFilter, setStaffFilter] = useState('');
  const [equipFilter, setEquipFilter] = useState('');
  const [isFilterStaffOpened, setIsFilterStaffOpened] = useState(false);
  const [isFilterEquipOpened, setIsFilterEquipOpened] = useState(false);

  const filteredStaff = STAFF.filter(staff => {
    return staff.name.toLowerCase().includes(staffFilter.toLowerCase())
  });

  const filteredEquip = EQUIPMENT.filter(equip => {
    return equip.id.toLowerCase().includes(equipFilter.toLowerCase())
  });

  const handleSearchStaff = (event) => {
    setStaffFilter(event.target.value);
  };

  const handleSearchEquip = (event) => {
    setEquipFilter(event.target.value);
  };

  const toggleFilterWindow = (filterType) => {
    if (filterType === 'staff') {
      setIsFilterStaffOpened(!isFilterStaffOpened);
    }
    if (filterType === 'equip') {
      setIsFilterEquipOpened(!isFilterEquipOpened);
    }
  };


  return(
    <>
    <section className="direction-block" id={id}>
      <section className='direction-general-info'>
        <Link className='direction-item-link' to={`/service-areas`}>
          <div style={{backgroundColor: `${props.color}`}} className='direction-item'>{name}</div>
        </Link>
        <button className='button button-delete-direction' onClick={() => onDeleteClick()}></button>
      </section>
      <section>
      <div className='chef-item'>
          {
            isAddingDirector ? (
              <>
              <p>Руководитель:</p>
              <input
                ref={directorInputRef}
                className='service-form-input'
                type='text'
                value={newDirectorName}
                onChange={handleDirectorInputChange}
                onKeyDown={handleKeyPress}
              />
              <button className='button button-add-director' onClick={handleAddDirector}></button>
              <button className='button button-close-director-input'  onClick={toggleDirectorInput}></button>
              </>
            ) :
            (
              <>
                <p>Руководитель: {director}</p>
                <button className='button button-edit' onClick={toggleDirectorInput}></button>
              </>
            )
          }
        </div>
      </section>
      <section className='direction-areas'>
        {
          areas.map((area, index) => (
            <div key={index} className='direction-area-wrapper'>
              <p className='direction-area' style={{backgroundColor: `${props.color}`}}>{area}</p>
            </div>
          ))
        }
        {isAddingArea ? (
            <>
            <div className='area-input-direction'>
              <input
                ref={areaInputRef}
                className='service-form-input'
                type='text'
                value={newAreaName}
                onChange={handleAreaInputChange}
                onKeyDown={handleAreaKeyPress}
              />
              <button
                className='button-add-area'
                type='button'
                onClick={handleAddArea}
              >
              </button>
            </div>
            </>
          ) : (
            <button
              className='button-add-area'
              type='button'
              onClick={() => setIsAddingArea(true)}
            >
            </button>
          )}
      </section>
      <section className="direction-section direction-section-stuff-members">
          <p className="direction-section-heading">Сотрудники</p>
          <div className="direction-section-wrapper">
              <div className="direction-section-header">
                <div className="direction-section-search-wrapper">
                  <input
                    className="direction-section-search-field"
                    type="text"
                    placeholder="Введите ФИО сотрудника..."
                    onChange={handleSearchStaff}
                  />
                </div>
                <button className="direction-button button-filter-info" onClick={() => toggleFilterWindow('staff')}></button>
              </div>

            <div className="direction-unit-wrapper">
                <ul className="direction-section-staff-list unit-list">
                {filteredStaff.map((staff, index) => (
                  <li key={index} className="unit-list-item">
                    {staff.name}
                    <button className="button-delete-unit"></button>
                  </li>
                ))}
              </ul>
          </div>
          <form className='search-form area-section-search'>
            <button className="direction-button button-add-unit">Добавить сотрудника</button>
          </form>
          </div>
          {isFilterStaffOpened && (
          <>
           <div className="filter-form filter-staff-form">
            <button className="direction-button filter-close-form-button" onClick={() => toggleFilterWindow('staff')} ></button>
              <div className="filter-form-wrapper">

                <button className="filter-form-staff-button">
                    Аналитики
                  </button>
                  <button className="filter-form-staff-button">
                    Работники с персоналом
                  </button>
                  <button className="filter-form-staff-button">
                    Работники
                  </button>
              </div>
              <button className="filter-apply-button area-button">Применить</button>
            </div>
          </>
          )}
        </section>

        <section className="direction-section direction-section-equipment">
          <p className="direction-section-heading">Оборудование</p>

          <div className="direction-section-wrapper">
              <div className="direction-section-header">
                <div className="direction-section-search-wrapper">
                  <input
                    className="direction-section-search-field"
                    type="text"
                    placeholder="Введите номер оборудования..."
                    onChange={handleSearchEquip}
                  />
                </div>
                <button className="direction-button button-filter-info" onClick={() => toggleFilterWindow('equip')}></button>
              </div>

            <div className="direction-unit-wrapper">
                <ul className="direction-section-equip-list unit-list">
                {filteredEquip.map((equip, index) => (
                  // <li key={index} className="equip-list-item unit-list-item" style={`background: url("${getimageForEquipment(equip.type)}") no-repeat center`}>
                  <li key={index} className="equip-list-item unit-list-item">
                    <div className="equip-item-wrapper">
                      <img src={getimageForEquipment(equip.type)} alt={equip.type} className="equip-type-icon"/>
                      {/* <img src={equipmentImages[equip.type]} alt={equip.type} className="equip-type-icon"/> */}
                      {equip.id}
                    </div>
                    <button className="button-delete-unit"></button>
                  </li>
                ))}
              </ul>
          </div>
          <form className='search-form area-section-search'>
            <button className="direction-button button-add-unit">Добавить оборудование</button>
          </form>
          </div>

          {isFilterEquipOpened && (
          <>
           <div className="filter-form filter-equip-form">
            <button className="direction-button filter-close-form-button" onClick={() => toggleFilterWindow('equip')} ></button>
              <div className="filter-form-wrapper">

                <button className="filter-form-equip-button filter-button-excavator">
                  </button>
                  <button className="filter-form-equip-button filter-button-bulldozer">
                  </button>
                  <button className="filter-form-equip-button filter-button-crane">
                  </button>
                  <button className="filter-form-equip-button filter-button-grader">
                  </button>
                  <button className="filter-form-equip-button filter-button-rink">
                  </button>
                  <button className="filter-form-equip-button filter-button-truck">
                  </button>
                  <button className="filter-form-equip-button filter-button-auto">
                  </button>

                {/* {Object.entries(equipmentImages).map(([key, src], index) => (
                  <button key={index} className="filter-form-button">
                    <img src={src} alt={key} className="filter-form-img" />
                  </button>
                ))
                } */}
              </div>
              <button className="filter-apply-button direction-button">Применить</button>
            </div>
          </>
          )}
        </section>
    </section>
    </>
  )
}

export default DirectionBlock;
