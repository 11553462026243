
const Loading = (Component) => {
  return function Loading({isLoading, ...props}) {
      if (!isLoading) return <Component {...props} />

      else return (
          <div className="table-loading">
          </div>
      )
  }
}
export default Loading;
