import { useState, useCallback, useEffect } from 'react'
import apiConfig from '../../../apiConfig.json';
import TaskCard from "./TaskCard";

const TasksCard = (props) => {

  const {accessToken, typeOfTasks, startDate, endDate} = props;

  const tasksStatus = {
    planned: 'Запланировано',
    inProgress: 'В работе',
    underReview: 'На проверке',
    underDevelopment: 'На доработке',
    done: 'Выполнено'
  }
  // ['Запланировано', 'В работе', 'На проверке', 'На доработке', 'Выполнено'];

  const [tasks, setTasks] = useState([{}]);

const getTasksInDateRange = useCallback(() => {
    fetch('', {
      method: 'POST',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(startDate, endDate)
    })
   .then(response =>  {
     if(response.ok) {
        getTasks();
     }
   })
   .catch(error => {
     console.error('Error fetching data about : ', error);
   });

 }, [accessToken]);

 const getDirections = useCallback(() => {
  fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_DIRECTIONS}`, {
    method: 'GET',
    headers: {
      'Authorization' : `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  })
 .then(response =>  {
   return response.json();
 })
 .then(data => {
  // console.log(data);

  const dirs = [];

  data.forEach((dir) => {
    const places = [];
    const name = dir.name;
    const direction_id = dir.id;

    if(dir.place.length > 0) {
      dir.place.forEach((place) => {
        const name = place.name;
        const place_id = place.id;
        places.push({name, place_id});
      });
    } else {
      // console.log('ну ка чё тут: ', places)
    }

    dirs.push({name, direction_id, places});
  });
  // setDirections(dirs);
 })
 .catch(error => {
   console.error('Error fetching data: ', error);
 });
},[accessToken]);

useEffect(() => {
 getDirections();
}, [accessToken]);


const getTasks = useCallback(() => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_EQUIPMENT}`, {
      method: 'GET',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
   .then(response =>  {
     return response.json();
   })
   .then(data => {
    console.log(data);
   })
   .catch(error => {
     console.error('Error fetching data about : ', error);
   });
}, [accessToken]);

useEffect(() => {
  getTasks()
}, []);

  return (
    <div className="tasks-card done-tasks-card">
      <div className="tasks-card__header">
        <span className="tasks-card-status">{typeOfTasks}</span>
        <span className="tasks-amount">{tasks.length} задач</span>
      </div>
      <div className="tasks-card-list__container">
        <ul className="tasks-card__list">
          <TaskCard
            // onOpen={onOpen}
          />
        </ul>
      </div>
    </div>
  )
};

export default TasksCard;
