import { useState, useEffect, useRef } from "react";
import './filter.css'
import '../UserEquipAddition/additionPage.css';

const Filters = ({onFilters}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [expanded, setExpanded] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [dates, setDates] = useState([]);
  const [beforeDate, setBeforeDate] = useState('');
  const [afterDate, setAfterDate] = useState('');
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [dateParameterClicked, setDateParameterClicked] = useState(false);
  const [technicTypeClicked, setTechnicTypeClicked] = useState(false);
  const [selectedTechnicType, setSelectedTechnicType] = useState('');
  const [datePeriodsArray, setDatePeriodsArray] = useState([
    { id: 0, afterDate: '', beforeDate: '' }
  ]);

  const [isLengthError, setIsLengthError] = useState({state: false, text: 'Поле не может быть пустым.'})

  const categoryColors = {
    'ФИО': '#6B66BB',
    'Логин': '#6B66BB',
    'VIN': '#F0D164',
    'Модель': '#F0D164',
    'Гаражный номер': '#F0D164',
    'Тип техники': '#F0D164',
    'Дата': '#E5A162',
    'Период': '#E5A162',
    'afterdate': '#E5A162',
    'beforedate': '#E5A162'
  }

  const categoriesWrapperRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (categoriesWrapperRef.current && !categoriesWrapperRef.current.contains(event.target)  && event.target !== buttonRef.current) {
        setCategoriesOpen(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleCloseFilterByEscape = (e) => {
      if (e.key === 'Escape') {
        onCloseFilter()
        // setCategoryOpen(false);
      }
    };
    document.body.addEventListener('keydown', handleCloseFilterByEscape);

    return () => {
      document.body.removeEventListener('keydown', handleCloseFilterByEscape);
    };
  }, []);

  const handleCategoryMenu = () => {
    setCategoriesOpen(!categoriesOpen);
    setExpanded(false);
  }

  const openInput = (category) => {
    setCategory(category);
    setCategoryOpen(true);
    setCategoriesOpen(false);
    if (category === "Дата" || category === "Период") {
      setDateParameterClicked(true);
      setTechnicTypeClicked(false);
    } else if (category === 'Тип техники') {
      setTechnicTypeClicked(true);
      setDateParameterClicked(false);
    } else {
      setDateParameterClicked(false);
      setTechnicTypeClicked(false);
    }
  }

  const handleAddDatePeriod = () => {
    setDatePeriodsArray(p => [
      ...p,
      {
        id: p.length,
        afterDate: '',
        beforeDate: ''
      }
    ])
  }

  const handleDeleteDatePeriod = (index) => {
    setDatePeriodsArray(p =>
      p.filter((_, i) => i !== index)
    )
  }

  const handleInputChange = (event) => {
    setIsLengthError(p=> ({...p, state: false}))
    setFilterValue(event.target.value);
  }

  const handleDatePeriodsChange = (e, currentId) => {
    setIsLengthError(p=> ({...p, state: false}))
      const { id, value } = e.target;
      setDatePeriodsArray(periods =>
        periods.map(period => {
         if (period.id === currentId) {
          const updatedPeriod = { ...period, [id === 'after-date' ? 'afterDate' : 'beforeDate']: value }
          if(id === 'after-date') {
            setAfterDate(p => {
              return [...p, value]
            }
          )
        } else {
            setBeforeDate(p => [...p, value])
          }
          return updatedPeriod;
         }
         return period;
        }
      )
      )
  }

  const handleAddFilter = (category, technicType) => {
  if(category === 'Период') {
      let allStartDates = [];
      let allEndDates = [];
      let datesForRender = [];
      datePeriodsArray.forEach(period => {
        if (!period.afterDate.length || !period.beforeDate.length) {
          setIsLengthError({state: true, text: 'Заполните все поля, чтобы продолжить.'})
          // console.log('period.afterDate ', period.afterDate, 'period.beforeDate ', period.beforeDate)
          // console.log('вроде остановились')
          return;
        } else {
          // console.log('не остановились')
          setIsLengthError({state: false, text: 'Поле не может быть пустым.'})
        }
        allStartDates.push(period.afterDate)
        allEndDates.push(period.beforeDate)
        datesForRender.push([period.afterDate, period.beforeDate])
      })
      // datePeriodsArray.map((period) => {
      //   allStartDates.push(period.afterDate)
      //   allEndDates.push(period.beforeDate)
      //   datesForRender.push([period.afterDate, period.beforeDate])
      // })
      // console.log('allStartDates.length ', allStartDates.length, 'allEndDates.length ', allEndDates.length, 'datePeriodsArray.length ',datePeriodsArray.length)
      // if (allStartDates.length !== datePeriodsArray.length || allEndDates.length !== datePeriodsArray.length ) {
      //   setIsLengthError({state: true, text: 'Заполните все поля, чтобы продолжить.'})
      //   console.log('вроде остановились')
      //   return;
      // } else {
      //   console.log('не остановились')
      //   setIsLengthError({state: true, text: 'Поле не может быть пустым.'})
      // }

      setDates(datesForRender)
      const dateStartDatesFilter = {category: 'afterdate', value: allStartDates}
      const dateEndDatesFilter = {category: 'beforedate', value: allEndDates}
      setFilterList(prev => {
        return [...prev, dateStartDatesFilter, dateEndDatesFilter]
      }
    );
      setBeforeDate('');
      setAfterDate('');

  } else if(category === 'Тип техники') {
    if (!technicType.length) {
      setIsLengthError({text: 'Выберите тип оборудования.', state: true})
      return;
    } else {
      setIsLengthError({text: 'Поле не может быть пустым.', state: false})
    }
    const newFilter = { category: category, value: technicType}
    setFilterList([...filterList, newFilter]);
    setSelectedTechnicType('');
  } else {
    if (!filterValue.length) {
      // console.log('Сработало?')
      // console.log(filterValue)
      setIsLengthError(p => ({...p, state: true}))
      return;
    } else {
      setIsLengthError(p => ({...p, state: false}))
    }

    const newFilter = { category: category, value: filterValue}
    // console.log('newFilter: ', newFilter)
    setFilterList([...filterList, newFilter]);
    setFilterValue('');
  }
}

  const handleRemoveFilter = (index, category) => {
    let updatedFilterList;
    if (category !== 'Период') {
      updatedFilterList = filterList.filter((_, i) => i !== index);
    } else {
      updatedFilterList = filterList;
    for (let i = updatedFilterList.length - 1; i >= 0; i--) {
      const filter = updatedFilterList[i];

  if (filter.category === 'afterdate' || filter.category === 'beforedate') {
    filter.value.splice(index, 1);
    if (filter.value.length === 0) {
      updatedFilterList.splice(i, 1);
    }
    }
  }
    setDates(p => {
      const updatedDates = [...p];
      updatedDates.splice(index, 1);
      return updatedDates
    });
  }

  setFilterList(updatedFilterList);
  if (updatedFilterList.length === 0) {
    onFilters({});
    setExpanded(false);
    setCategoriesOpen(false);
  } else {
    const updatedFilters = updatedFilterList.reduce((acc, curr) => {
      let translatedValue = curr.value;
      if (curr.category === 'Дата') {
        const dateParts = curr.value.split('.');
        if (dateParts.length === 3) {
          translatedValue = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        }
      }

      const translatedCategory = categoryTranslation[curr.category] || curr.category;

      if (!acc[translatedCategory]) {
        acc[translatedCategory] = [];
      }

      if(translatedCategory === "afterdate" || translatedCategory === "beforedate") {
        acc[translatedCategory] = translatedValue;
      } else {
        acc[translatedCategory].push(translatedValue);
      }
      return acc;
    }, {});

    const filtersMap = new Map(Object.entries(updatedFilters).map(([key, value]) => [key, value]));
    let requestBody = {};
    if (filtersMap.size > 0) {
      requestBody = {
        filters: Object.fromEntries(filtersMap)
      };
    }
    onFilters(requestBody);
  }
}

  const categoryTranslation = {
    'Дата' : 'start',
    'Период': 'period',
    'Логин' : 'name',
    'ФИО' : 'fullname',
    'VIN' : 'vin',
    'Модель' : 'model',
    'Тип техники' : 'type',
    'Гаражный номер' : 'warrantynumber'
  }

  const getImageForEquipmentType = (equipmentType) => {
    switch (equipmentType) {
      case 'EXCAVATOR':
        return require('../../Images/Equipment/excavator_32.png');
      case 'BULLDOZER':
        return require('../../Images/Equipment/bulldozer_32.png');
      case 'CRANE':
        return require('../../Images/Equipment/crane_32.png');
      case 'GRADER':
        return require('../../Images/Equipment/grader_32.png');
      case 'RINC':
        return require('../../Images/Equipment/rinc_32.png');
      case 'RINK':
        return require('../../Images/Equipment/rink_32.png');
      case 'TRUCK':
        return require('../../Images/Equipment/truck_32.png');
      case 'POOKER':
        return require('../../Images/Equipment/camera_10.png');
      case 'AUTO':
        return require('../../Images/Equipment/auto_32.png');
      case 'REFUELER':
        return require('../../Images/Equipment/refueler_512.png');
      default:
        return require('../../Images/Equipment/excavator_32.png');
    }
  };

  const filters = filterList.reduce((acc, curr) => {
    let translatedValue = curr.value;
    // console.log(translatedValue)
    const translatedCategory = categoryTranslation[curr.category] || curr.category;
    if(translatedCategory === 'warrantynumber') {
      translatedValue = parseInt(curr.value, 10);
    }
    if(!acc[translatedCategory]) {
      acc[translatedCategory] = [];
    }
    if(translatedCategory === "afterdate" || translatedCategory === "beforedate") {
        acc[translatedCategory] = translatedValue;
    } else if (translatedCategory === 'fullname') {
      const valueInCorrectCase = translatedValue.charAt(0).toUpperCase() + translatedValue.slice(1).toLowerCase()
      acc[translatedCategory].push(valueInCorrectCase);
    } else if(translatedCategory === 'name') {
      const valueInCorrectCase =
      translatedValue.charAt(0).toUpperCase()
      + translatedValue.slice(1, -2).toLowerCase()
      + translatedValue.slice(-2).toUpperCase();
      acc[translatedCategory].push(valueInCorrectCase);
    }else {
      acc[translatedCategory].push(translatedValue);
    }
    return acc;
  }, {});

  const sendFiltersToMain = () => {
    if (isLengthError.state === true) {
      return;
    }
    const filtersMap = new Map(Object.entries(filters).map(([key, value]) => [key, value]));
    let requestBody = {};
    if (filtersMap.size > 0) {
        requestBody = {
            filters : Object.fromEntries(filtersMap)
        };
    }
    if (selectedTechnicType) {
      requestBody = {
        ...requestBody,
        technictype: selectedTechnicType
      };
    }
    onFilters(requestBody);
    setCategoriesOpen(false);
    setExpanded(false);
    setCategoryOpen(false)
    }

  const onCloseFilter = () => {
    setIsLengthError(p => ({...p, state: false}))
    setCategoryOpen(false)
    setDatePeriodsArray([ { id: 0, afterDate: '', beforeDate: '' } ]);
    setFilterValue('')
    setCategory('')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddFilter(category);
    }
  };

  const handleKeyUp = (event) => {

    if(isLengthError.state) {
      return;
    }

    if (event.key === 'Enter') {
      sendFiltersToMain();
      setCategoryOpen(false);
    }
  };
// else if(event.key === 'Escape') {
//       console.log('Это... сработало?')
//       onCloseFilter()
//     }
  const handleTechnicTypeClick = (technicType) => {
    setSelectedTechnicType(technicType);
    handleAddFilter('Тип техники', technicType);
  }

  return(
    <>
          <section className={ isMobile ? 'filter filter-mobile' : 'filter'}>
            <div className={isMobile ? 'filter-wrapper-mobile' : 'filter-wrapper'}>
              <button ref={buttonRef} className='button-filter' onClick={handleCategoryMenu}></button>
              {
                categoryOpen && (
                  <>
                    <div className="filter-component-wrapper">
                      <div className="filter-category-wrapper" style={{ backgroundColor: categoryColors[category] }}>
                        <button className="filter-category-wrapper__close-button" type="button" onClick={onCloseFilter}></button>
                        <input
                          type="text"
                          className="filter-category-wrapper__input"
                          placeholder={`Введите ${category}`}
                          value={filterValue}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          onKeyUp={handleKeyUp}
                        />
                          {isLengthError.state &&
                            <p className="validation-error-message">{isLengthError.text}</p>
                          }
                        <button
                          className="filter-category-wrapper__button"
                          onClickCapture={() => handleAddFilter(category)}
                          onClick={() => sendFiltersToMain()}
                        >
                          Применить
                        </button>
                      </div>
                    </div>
                  </>
                )
              }
              {
                categoryOpen && dateParameterClicked && (
                  <>
                    <div className="filter-component-wrapper">
                      <div className="filter-category-wrapper" style={{ backgroundColor: categoryColors[category] }}>
                      {/* <div className="filter-category-wrapper"> */}
                        <button className="filter-category-wrapper__close-button" type="button" onClick={onCloseFilter}></button>
                        {category === 'Дата' &&
                          <>
                          <input
                            type="date"
                            className="filter-category-wrapper__input"
                            value={filterValue}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            onKeyUp={handleKeyUp}
                          />
                          {isLengthError.state &&
                            <p className="validation-error-message">{isLengthError.text}</p>
                          }
                          </>
                        }
                        {category === 'Период' &&
                          <>
                            {datePeriodsArray.map((item, index) => (
                            <div key={item.id} className="filter-category-wrapper__period-wrapper">
                              <button className="filter-category__close-btn" onClick={() => handleDeleteDatePeriod(index)} />
                              <input
                                type="date"
                                className="filter-category-wrapper__input"
                                id='after-date'
                                value={item.afterDate}
                                onChange={(e) => handleDatePeriodsChange(e, item.id)}
                                onKeyDown={handleKeyDown}
                                onKeyUp={handleKeyUp}
                              />
                              <input
                                type="date"
                                className="filter-category-wrapper__input"
                                id='before-date'
                                value={item.beforeDate}
                                onChange={(e) => handleDatePeriodsChange(e, item.id)}
                                onKeyDown={handleKeyDown}
                                onKeyUp={handleKeyUp}
                              />
                            </div>

                          ))}
                          {isLengthError.state &&
                            <p className="validation-error-message">{isLengthError.text}</p>
                          }
                          <button className="filter-category-dates__add-button" onClick={handleAddDatePeriod}></button>
                        </>
                      }
                        <button
                        className="filter-category-wrapper__button"
                        onClickCapture={() => handleAddFilter(category)}
                        onClick={() => sendFiltersToMain()}
                        >
                          Применить
                        </button>
                      </div>
                    </div>
                  </>
                )
              }
              {
                categoryOpen && technicTypeClicked && (
                  <>
                    <div className="filter-component-wrapper">
                      <div className="filter-category-wrapper" style={{backgroundColor: categoryColors[category]}}>
                        <button className="filter-category-wrapper__close-button" type="button" onClick={onCloseFilter}></button>
                        <div className="filter-category-wrapper__type-list">
                          <button type='button' className="button-apply-equipment technic-type-white-excavator" onClickCapture={() => handleTechnicTypeClick('EXCAVATOR')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-bulldozer" onClickCapture={() => handleTechnicTypeClick('BULLDOZER')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-crane" onClickCapture={() => handleTechnicTypeClick('CRANE')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-grader" onClickCapture={() => handleTechnicTypeClick('GRADER')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-rink" onClickCapture={() => handleTechnicTypeClick('RINK')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-truck" onClickCapture={() => handleTechnicTypeClick('TRUCK')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-auto" onClickCapture={() => handleTechnicTypeClick('AUTO')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-pooker" onClickCapture={() => handleTechnicTypeClick('POOKER')}></button>
                          <button type='button' className="button-apply-equipment technic-type-white-refueler" onClickCapture={() => handleTechnicTypeClick('REFUELER')}></button>
                        </div>
                        {isLengthError.state &&
                            <p className="validation-error-message">{isLengthError.text}</p>
                          }
                        <button
                          className="filter-category-wrapper__button"
                          onClick={() => sendFiltersToMain()}
                        >
                          Применить
                        </button>
                      </div>
                    </div>
                  </>
                )
              }
              <div className="filter__applied-filter-wrapper">
                {dates.length > 0 &&
                 dates.map((period, index) => {
                  return (
                   <li key={index} className="filter-item" style={{backgroundColor: categoryColors['Период']}}>
                     {`${period[0]} - ${period[1]}`}
                     <button onClick={() => handleRemoveFilter(index, 'Период')}>x</button>
                   </li>
                  )
                 }
                )
                }
                {
                  filterList.map((filter, index) => {
                    let filterValue = [];
                    // console.log(filterValue)
                    if(filter.category === 'Дата') {
                      const dateParts = filter.value.split('-');
                      filterValue = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
                      return (
                        <li key={index} className="filter-item" style={{backgroundColor: categoryColors[filter.category]}}>
                          {filterValue}
                          <button onClick={() => handleRemoveFilter(index)}>x</button>
                        </li>
                      )
                    }
                     if (filter.category === 'Тип техники') {
                      const getImageForEquipmentType = (equipmentType) => {
                        switch (equipmentType) {
                          case 'EXCAVATOR':
                            return require('../../Images/Equipment/excavator_32.png');
                          case 'BULLDOZER':
                            return require('../../Images/Equipment/bulldozer_32.png');
                          case 'CRANE':
                            return require('../../Images/Equipment/crane_32.png');
                          case 'GRADER':
                            return require('../../Images/Equipment/grader_32.png');
                          case 'RINC':
                            return require('../../Images/Equipment/rinc_32.png');
                          case 'RINK':
                            return require('../../Images/Equipment/rink_32.png');
                          case 'TRUCK':
                            return require('../../Images/Equipment/truck_32.png');
                          case 'POOKER':
                            return require('../../Images/Equipment/camera_10.png');
                          case 'AUTO':
                            return require('../../Images/Equipment/auto_32.png');
                          case 'REFUELER':
                            return require('../../Images/Equipment/refueler_512.png');
                          default:
                            return require('../../Images/Equipment/excavator_32.png');
                        }
                      };

                      return(
                        <li key={index} className="filter-item filter-item-technic-type" style={{backgroundColor: categoryColors[filter.category]}}>
                          <img src={getImageForEquipmentType(filter.value)} width={25} height={20} alt="тип техники" className="technic-type-chosen"/>
                          <button onClick={() => handleRemoveFilter(index)}>x</button>
                        </li>
                      )
                    }
                     filterValue = filter.value;
                    if (filter.category !== 'afterdate' && filter.category !== 'beforedate')
                     return(
                      <li key={index} className="filter-item" style={{backgroundColor: categoryColors[filter.category]}}>
                        {filterValue}
                        <button onClick={() => handleRemoveFilter(index)}>x</button>
                      </li>
                    )
                  })
                }
              </div>
              {
              categoriesOpen &&
              <div ref={categoriesWrapperRef} className={isMobile ? 'categories-wrapper categories-wrapper-mobile' : 'categories-wrapper'}>
              <ul className="categories-list">
                <li className="categories-items">
                  <button onClick={() => openInput('Дата')}>Дата</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('Период')}>Период</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('Логин')}>Логин</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('ФИО')}>ФИО</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('VIN')}>VIN</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('Модель')}>Модель</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('Тип техники') && handleCategoryMenu}>Тип техники</button>
                </li>
                <li className="categories-items">
                  <button onClick={() => openInput('Гаражный номер')}>Гаражный номер</button>
                </li>
              </ul>
            </div>
            }
            </div>
      </section>
    </>
  )
}
export default Filters;
