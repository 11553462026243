import { useState } from "react";
import DetailedCardModal from "./DetailedCardModal";
// import ModalDelete from "../../Service/ModalDelete";

const TaskCard = ({onOpen}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const colors = ['#9B69BA', '#708EC9', '#E5A162', '#F0D164'];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <li className="task-card__item">
      <section className="card__info-general">
        <div className="card__info-general-direction">
          <span className="info-direction" style={{backgroundColor: `${colors[0]}`}}>ДН-1</span>
          <span className="info-dates">21.07.2024 - 31.07.2024</span>
        </div>
        <div className="task-card__actions">
          <button className="edit-card__button card-button"></button>
          <button className="delete-card__button card-button"
            onClick={onOpen}
          ></button>
        </div>
      </section>

      <p className="card__info-description">
        Разработка грунта ковшом
      </p>

      <section className="card__info-other">
        <ul className="info-other-staff__list">
          <li className="staff-list__item">
            <img src="#" alt="" className="staff-item__avatar" />
          </li>
          <li className="staff-list__item">
            <img src="#" alt="" className="staff-item__avatar" />
          </li>
          <li className="staff-list__item">
            <img src="#" alt="" className="staff-item__avatar" />
          </li>
          <li className="staff-list__item">
            <img src="#" alt="" className="staff-item__avatar" />
          </li>
          <li className="staff-list__item">
            <img src="#" alt="" className="staff-item__avatar" />
          </li>
        </ul>
          <span className="info-location">
            Мустах
          </span>
      </section>

      <button 
        className='tracker-goto-button tracker-goto-arrow-button rotated-right-bottom'
        onClick={handleOpenModal}>
      </button>

      <DetailedCardModal
          color={colors[0]}
          isOpened={isModalOpen}
          onClose={handleCloseModal}
      />
    </li>
  )
}

export default TaskCard;