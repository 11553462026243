import { useState } from 'react';
import './areas.css';

const AreaBlock = (props) => {

  const EQUIPMENT = [
    {
      id: '1111',
      type: 'auto'
    },
    {
      id: '2222',
      type: 'bulldozer'
    },
    {
      id: '3333',
      type: 'grader'
    },
    {
      id: '4444',
      type: 'rink'
    },
    {
      id: '5555',
      type: 'truck'
    },
    {
      id: '6666',
      type: 'truck'
    },
    {
      id: '7777',
      type: 'auto'
    },
    {
      id: '8888',
      type: 'grader'
    },
    {
      id: '9999',
      type: 'bulldozer'
    },
  ]

  const STAFF = [
    {
      name: 'Иванов Иван Иванович',
      role: 'analytic'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'analytic'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee with stuff'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee with stuff'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee with stuff'
    },
    {
      name: 'Иванов Иван Иванович',
      role: 'employee'
    }
  ]

  const getimageForEquipment = (equipmentType) => {
    switch (equipmentType) {
      case 'excavator':
        return require('../../Images/Equipment/excavator_32.png');
      case 'bulldozer':
        return require('../../Images/Equipment/bulldozer_32.png');
      case 'crane':
        return require('../../Images/Equipment/crane_32.png');
      case 'grader':
        return require('../../Images/Equipment/grader_32.png');
      case 'rink':
        return require('../../Images/Equipment/rink_32.png');
      case 'truck':
        return require('../../Images/Equipment/truck_32.png');
      case 'auto':
        return require('../../Images/Equipment/auto_32.png');
      default:
        return require('../../Images/Equipment/excavator_32.png');
    }
  }

  const [staffFilter, setStaffFilter] = useState('');
  const [equipFilter, setEquipFilter] = useState('');
  const [isFilterStaffOpened, setIsFilterStaffOpened] = useState(false);
  const [isFilterEquipOpened, setIsFilterEquipOpened] = useState(false);
  const onDeleteClick = props.onDeleteClick;

  const filteredStaff = STAFF.filter(staff => {
    return staff.name.toLowerCase().includes(staffFilter.toLowerCase())
  });

  const filteredEquip = EQUIPMENT.filter(equip => {
    return equip.id.toLowerCase().includes(equipFilter.toLowerCase())
  });

  const handleSearchStaff = (event) => {
    setStaffFilter(event.target.value);
  };

  const handleSearchEquip = (event) => {
    setEquipFilter(event.target.value);
  };

  const toggleFilterWindow = (filterType) => {
    if (filterType === 'staff') {
      setIsFilterStaffOpened(!isFilterStaffOpened);
    }
    if (filterType === 'equip') {
      setIsFilterEquipOpened(!isFilterEquipOpened);
    }
  };

  return(
    <>
      <div className="areas-block">
        <div className="general-info-area">
          <p className='direction-area' style={{backgroundColor: '#9B69BA'}}>Шугара</p>
          <button className='button button-delete-direction' onClick={() => onDeleteClick()}></button>
        </div>

        <section className="area-section area-section-stuff-members">
          <p className="area-section-heading">Сотрудники</p>
          <div className="area-section-wrapper">
              <div className="area-section-header">
                <div className="area-section-search-wrapper">
                  <input
                    className="area-section-search-field"
                    type="text"
                    placeholder="Введите ФИО сотрудника..."
                    onChange={handleSearchStaff}
                  />
                </div>
                <button className="area-button button-filter-info" onClick={() => toggleFilterWindow('staff')}></button>
              </div>

            <div className="area-unit-wrapper">
                <ul className="area-section-staff-list unit-list">
                {filteredStaff.map((staff, index) => (
                  <li key={index} className="unit-list-item">
                    {staff.name}
                    <button className="button-delete-unit"></button>
                  </li>
                ))}
              </ul>
          </div>
          <form className='search-form area-section-search'>
            <button className="area-button button-add-unit">Добавить сотрудника</button>
          </form>
          </div>
          {isFilterStaffOpened && (
          <>
           <div className="filter-form filter-staff-form">
            <button className="area-button filter-close-form-button" onClick={() => toggleFilterWindow('staff')} ></button>
              <div className="filter-form-wrapper">

                <button className="filter-form-staff-button">
                    Аналитики
                  </button>
                  <button className="filter-form-staff-button">
                    Работники с персоналом
                  </button>
                  <button className="filter-form-staff-button">
                    Работники
                  </button>
              </div>
              <button className="filter-apply-button area-button">Применить</button>
            </div>
          </>
          )}
        </section>

        <section className="area-section are-section-equipment">
          <p className="area-section-heading">Оборудование</p>

          <div className="area-section-wrapper">
              <div className="area-section-header">
                <div className="area-section-search-wrapper">
                  <input
                    className="area-section-search-field"
                    type="text"
                    placeholder="Введите номер оборудования..."
                    onChange={handleSearchEquip}
                  />
                </div>
                <button className="area-button button-filter-info" onClick={() => toggleFilterWindow('equip')}></button>
              </div>

            <div className="area-unit-wrapper">
                <ul className="area-section-equip-list unit-list">
                {filteredEquip.map((equip, index) => (
                  // <li key={index} className="equip-list-item unit-list-item" style={`background: url("${getimageForEquipment(equip.type)}") no-repeat center`}>
                  <li key={index} className="equip-list-item unit-list-item">
                    <div className="equip-item-wrapper">
                      <img src={getimageForEquipment(equip.type)} alt={equip.type} className="equip-type-icon"/>
                      {/* <img src={equipmentImages[equip.type]} alt={equip.type} className="equip-type-icon"/> */}
                      {equip.id}
                    </div>
                    <button className="button-delete-unit"></button>
                  </li>
                ))}
              </ul>
          </div>
          <form className='search-form area-section-search'>
            <button className="area-button button-add-unit">Добавить оборудование</button>
          </form>
          </div>

          {isFilterEquipOpened && (
          <>
           <div className="filter-form filter-equip-form">
            <button className="area-button filter-close-form-button" onClick={() => toggleFilterWindow('equip')} ></button>
              <div className="filter-form-wrapper">

                <button className="filter-form-equip-button filter-button-excavator">
                  </button>
                  <button className="filter-form-equip-button filter-button-bulldozer">
                  </button>
                  <button className="filter-form-equip-button filter-button-crane">
                  </button>
                  <button className="filter-form-equip-button filter-button-grader">
                  </button>
                  <button className="filter-form-equip-button filter-button-rink">
                  </button>
                  <button className="filter-form-equip-button filter-button-truck">
                  </button>
                  <button className="filter-form-equip-button filter-button-auto">
                  </button>

                {/* {Object.entries(equipmentImages).map(([key, src], index) => (
                  <button key={index} className="filter-form-button">
                    <img src={src} alt={key} className="filter-form-img" />
                  </button>
                ))
                } */}
              </div>
              <button className="filter-apply-button area-button">Применить</button>
            </div>
          </>
          )}
        </section>
      </div>
    </>
  )
}
export default AreaBlock;
