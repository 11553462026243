import { useEffect, useState } from "react";
import GalleryPopUp from "../GalleryPopUp";
import img from '../../../Images/icons8-img-100.png';
import apiConfig from "../../../apiConfig.json";

const OverlayShiftMobile = ({show, onClose, name, vin, date, start, end, place, trips, imagesArray, equipmentType, mainRoute, sortedRoute, sortedVoltage, toggleMapPopup, toggleGraphPopup}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [isPhotoOpen, setIsPhotoOpen] = useState(false);
  const [openTrips, setOpenTrips] = useState(Array(trips.length).fill(false));
  const [showGalleryPopUp, setShowGalleryPopUp] = useState(false);
  const [image, setImage] = useState('');
  const [images, setImages] = useState([]);



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);


  const openImages = () => {
    setIsPhotoOpen(true);
  };
  const closeImages = () => {
    setIsPhotoOpen(false);
  };


  const openTrip = (index) => {
    const updatedOpenTrips = [...openTrips];
    updatedOpenTrips[index] = true;
    setOpenTrips(updatedOpenTrips);
  };
  const closeTrip = (index) => {
    const updatedOpenTrips = [...openTrips];
    updatedOpenTrips[index] = false;
    setOpenTrips(updatedOpenTrips);
  };

  const toggleGalleryPopUp = (images, image) => {
    setShowGalleryPopUp(!showGalleryPopUp);
    setImages(images);
    setImage(image);
  }

  if(!show) return null

  return(
    <>
      <GalleryPopUp show={showGalleryPopUp} onClose={toggleGalleryPopUp} imagesArray={images} image={image}/>
      <section className={isMobile ? 'overlay-shift' : 'inactive'}>
        <div className="info-container-wrapper">
          <div className="button-name-wrapper">
            <p>{name}</p>
            <div className="close-button-wrapper">
              <button className="close-shift-overlay" onClick={onClose}></button>
            </div>
          </div>
          <div className="deco-line"></div>
          <section className="info-container">
            <ul className="shift-info-list">
              <li className="shift-info-item">
                <p>VIN</p>
                <p>{vin}</p>
              </li>
              <li className="shift-info-item">
                <p>Дата</p>
                <p>{date}</p>
              </li>
              <li className="shift-info-item">
                <p>Начало</p>
                <p>{start}</p>
              </li>
              <li className="shift-info-item">
                <p>Конец</p>
                <p>{end}</p>
              </li>
              <li className="shift-info-item">
                <p>Место</p>
                <p>{place}</p>
              </li>
              {
                mainRoute && (
                  <li className="shift-info-item">
                    <p>Маршрут</p>
                    <button className="button-show-map" onClick={() => toggleMapPopup(mainRoute, sortedRoute)}></button>
                  </li>
                )
              }
              {
                Object.keys(sortedVoltage).length > 0 && (
                  <li className="shift-info-item">
                    <p>Напряжение</p>
                    <button className="button-show-graph" onClick={() => toggleGraphPopup(sortedVoltage)}></button>
                  </li>
                )
              }
            </ul>
            <section className="shift-images">
              {isPhotoOpen ? (
                <button className="button-shift-images shift-images-close" onClick={closeImages}>
                  <p>Фото</p>
                </button>
              ) : (
                <button className="button-shift-images shift-images-open" onClick={openImages}>
                  <p>Фото</p>
                </button>
              )
              }

              {isPhotoOpen &&
                <ul className="shift-images-list">
                   {
                      imagesArray.map((image, index) => {
                        const tripsImage = `${apiConfig.BASE_URL}` + image.value;
                        return(
                          <li className="shift-images-item" key={index}>
                            <button className="shift-images-button" onClick={() => toggleGalleryPopUp(imagesArray, tripsImage)}>
                              {equipmentType === 'AUTO' ? (
                                <img className="fill-image" src={img} width={125} height={70} alt='фото смены'/>
                              ) : (
                                <img src={tripsImage} width={125} height={70} alt='фото смены'/>
                              )}
                            </button>
                          </li>
                        )
                      })
                    }
                </ul>
              }
            </section>
          </section>
          <section className="info-trips">
              <p>Рейсы</p>
              <div className="deco-line"></div>
              <ul className="trips-list">
                {
                  trips.map((trip, index) => {
                    const tripKey = index;
                    const formatDate = (dateString) => {
                      const date = new Date(dateString);
                      const day = String(date.getDate()).padStart(2, '0');
                      const month = String(date.getMonth() + 1).padStart(2, '0');
                      const year = date.getFullYear();
                      return `${day}.${month}.${year}`;
                    };
                    const formatTime = (dateString) => {
                      const date = new Date(dateString);
                      const hours = String(date.getHours()).padStart(2, '0');
                      const minutes = String(date.getMinutes()).padStart(2, '0');
                      return `${hours}:${minutes}`;
                    };
                    const tripPlace = trip.place;
                    const tripWorktype = trip.workType;
                    const tripImagesArray = Object.keys(trip.photosImagePath).map(key => ({key, value: trip.photosImagePath[key]}));
                    const sortedRouteKey = Object.keys(trip.route).sort();
                    const sortedRoute = {};
                    sortedRouteKey.forEach(key => {
                      sortedRoute[key] = trip.route[key];
                    });

                    return (
                        <li className="trips-item" key={tripKey}>
                        { !openTrips[index] &&
                          <div className="open-trip-button-wrapper">
                            <button className="open-trip-button" onClick={() => openTrip(index)}>Рейс {tripKey + 1}</button>
                          </div>
                        }

                        { openTrips[index] &&
                              <ul className="trip-info-list">
                              <li className="trip-info-item">
                                <div className="close-trip-button-wrapper">
                                  <button className="close-trip-button" onClick={() => closeTrip(index)}></button>
                                </div>
                              </li>
                              <li className="trip-info-item">
                                <p>Дата</p>
                                <p>{formatDate(trip.start)}</p>
                              </li>
                              <li className="trip-info-item">
                                <p>Начало</p>
                                <p>{formatTime(trip.start)}</p>
                              </li>
                              <li className="trip-info-item">
                                <p>Конец</p>
                                <p>{formatTime(trip.end)}</p>
                              </li>
                              <li className="trip-info-item">
                                <p>Место</p>
                                <p>{tripPlace}</p>
                              </li>
                              <li className="trip-info-item">
                                <p>Тип работы</p>
                                <p>{tripWorktype}</p>
                              </li>
                              {
                                Object.keys(sortedRoute).length > 0 && (
                                  <li className="trip-info-item">
                                    <p>Маршрут</p>
                                    <button className="button-show-map" onClick={() => toggleMapPopup(mainRoute, sortedRoute)}></button>
                                  </li>
                                )
                              }
                              <li>
                                <p>Фото</p>
                                <ul className="shift-images-list">
                                {
                                  tripImagesArray.map((tripImage, index) => {
                                    const tripsImage = `${apiConfig.BASE_URL}` + tripImage.value;
                                    return(
                                      <li className="trip-images-item" key={index}>
                                        <button className="shift-images-button" onClick={() => toggleGalleryPopUp(tripImagesArray, tripsImage)}>
                                          {equipmentType === 'AUTO' ? (
                                            <img src={img} width={105} height={80} alt='фото смены'/>
                                          ) : (
                                            <img src={tripsImage} width={105} height={80} alt='фото смены'/>
                                          )}
                                        </button>
                                      </li>
                                    )
                                  })
                                }
                                </ul>
                              </li>
                            </ul>
                            }
                      </li>
                    )
                  })
                }
              </ul>
          </section>
        </div>
      </section>
    </>
  );
};

export default OverlayShiftMobile;
