import { useEffect, useState } from "react";
import warning from '../../../Images/icons8-warning-24.png';


const OverlayWarningMobile = ({show, onClose}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);


  if(!show) return null



  return(
    <>
      <section className={isMobile ? 'overlay-shift' : 'inactive'}>
        <div className="info-container-wrapper warning-info-wrapper">
          <div className="button-name-wrapper warning-head-wrapper">
            <img src={warning} alt="vin car" width={20} height={20} className="warning-icon"/>
            <p>Поломка экскаватора</p>
            <div className="close-button-wrapper">
              <button className="close-shift-overlay" onClick={onClose}></button>
            </div>
          </div>
          <div className="deco-line"></div>
          <section className="warning-info">
            <ul className="warning-list">
              <li className="warning-item">
                <p>Описание проблемы</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit </p>
              </li>
              <li className="warning-item">
                <p>Голосовое сообщение</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
              </li>
              <li className="warning-item">
                <p>Фото</p>
                <ul className="warning-images-list">
                  <li className="warning-images-item">
                    <img src='#' alt="deffect"/>
                  </li>
                </ul>
              </li>
            </ul>
          </section>
        </div>
      </section>
    </>
  );
};

export default OverlayWarningMobile;
