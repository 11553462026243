import { useEffect, useState } from "react";
import GalleryPopUp from "../GalleryPopUp";
import apiConfig from "../../../apiConfig.json";


const OverlayVinMobile = ({show, onClose, vin, model, warrantyNumber, technicType, imagesArray}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [showGalleryPopUp, setShowGalleryPopUp] = useState(false);
  const [image, setImage] = useState('');
  const [images, setImages] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const getImageForEquipmentType = (technicType) => {
    switch (technicType) {
      case 'EXCAVATOR':
        return require('../../../Images/Equipment/excavator_32.png');
      case 'BULLDOZER':
        return require('../../../Images/Equipment/bulldozer_32.png');
      case 'CRANE':
        return require('../../../Images/Equipment/crane_32.png');
      case 'GRADER':
        return require('../../../Images/Equipment/grader_32.png');
      case 'RINC':
        return require('../../../Images/Equipment/rinc_32.png');
      case 'RINK':
        return require('../../../Images/Equipment/rink_32.png');
      case 'TRUCK':
        return require('../../../Images/Equipment/truck_32.png');
      case 'POOKER':
        return require('../../../Images/Equipment/camera_10.png');
      case 'AUTO':
        return require('../../../Images/Equipment/auto_32.png');
      case 'REFUELER':
        return require('../../../Images/Equipment/refueler_512.png');
      default:
        return require('../../../Images/Equipment/excavator_32.png');
    }
  };

  const toggleGalleryPopUp = (images, image) => {
    setShowGalleryPopUp(!showGalleryPopUp);
    setImages(images);
    setImage(image);
  }

  // console.log(imagesArray)

  if(!show) return null



  return(
    <>
      <GalleryPopUp show={showGalleryPopUp} onClose={toggleGalleryPopUp} imagesArray={images} image={image}/>
      <section className={isMobile ? 'overlay-shift' : 'inactive'}>
        <div className="info-container-wrapper vin-info-wrapper">
          <div className="button-name-wrapper">
            <img src={getImageForEquipmentType(technicType)} alt="vin car" width={60} height={60} className="car-image"/>
            <div className="close-button-wrapper">
              <button className="close-shift-overlay" onClick={onClose}></button>
            </div>
          </div>
          <div className="deco-line"></div>
          <section className="vin-info">
            <ul className="vin-list">
              <li className="vin-item">
                <p>VIN</p>
                <p>{vin}</p>
              </li>
              <li className="vin-item">
                <p>Гаражный номер</p>
                <p>{warrantyNumber}</p>
              </li>
              <li className="vin-item">
                <p>Модель</p>
                <p>{model}</p>
              </li>
              <li className="vin-item">
                <p>Тип техники</p>
                <p>{technicType}</p>
              </li>
              <li>
                <ul className="shift-images-list">
                  {
                    imagesArray.map((image, index) => {
                      const img = `${apiConfig.BASE_URL}` + image.value;
                      return(
                        <li className="shift-images-item" key={index}>
                          <button className="shift-images-button" onClick={() => toggleGalleryPopUp(imagesArray, img)}>
                            <img src={img} width={125} height={100} alt="shift"/>
                          </button>
                        </li>
                      )
                    })
                  }
                </ul>
              </li>
            </ul>
          </section>
        </div>
      </section>
    </>
  );
};

export default OverlayVinMobile;
