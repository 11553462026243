import { useCallback, useEffect, useRef, useState } from "react";
import apiConfig from "../apiConfig.json";
import './loadApkFile.css'


const LoadApkFile = ({accessToken}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [apkFile, setApkFile] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [buttonTop, setButtonTop] = useState(0);
  const downloadMessageRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (downloadMessageRef.current && !downloadMessageRef.current.contains(event.target)  && event.target !== buttonRef.current) {
        setDownloadStatus(null);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const fetchApkFile = useCallback(() => {
    setDownloadStatus('downloading');
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_APK}`, {
      method: 'GET',
      headers:{
        'Authorization' : `Bearer ${accessToken}`,
        // 'Content-Type' : 'application/vnd.android.package-archive'
      }
    })
    .then(response => {
      if (!response.ok) {
        console.error(`Response status: ${response.status} - ${response.statusText}`);
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      setApkFile(blob);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'mon-str.apk');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadStatus('downloaded');
    })
    .catch(error => {
      console.error('Error fetching APK file:', error);
      setDownloadStatus('error');
    });
  }, [accessToken]);


  const handleDownloadApk = () => {
    fetchApkFile();
    updateButtonPosition();
  }

  const updateButtonPosition = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setButtonTop(buttonRect.top + window.scrollY - 20);
    }
  };

  console.log(apkFile);

  return(
    <>
      {isMobile ? (
        <button className='button-yellow button' onClick={handleDownloadApk} ref={buttonRef}></button>
      ) : (
        <button className='button-yellow button-download-desktop' onClick={handleDownloadApk} ref={buttonRef}></button>
      )}
      {downloadStatus === 'downloading' && <p className="download-message download-loading-message" ref={downloadMessageRef} style={{ top: `${buttonTop}px` }}>Файл скачивается</p>}
      {downloadStatus === 'downloaded' && <p className="download-message download-successfull-message" ref={downloadMessageRef} style={{ top: `${buttonTop}px` }}>Файл скачался успешно</p>}
      {downloadStatus === 'error' && <p className="download-message download-error-message" ref={downloadMessageRef} style={{ top: `${buttonTop}px` }}>Произошла ошибка при скачивании. Попробуйте еще раз.</p>}
    </>
  )
}

export default LoadApkFile;

