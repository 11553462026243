import { useState, useEffect, useCallback, useRef } from "react";
import { Link } from 'react-router-dom'
import NavigationMenu from "../../Components/NavigationMenu";
import EquipForm from "./EquipForm";
import apiConfig from '../../apiConfig.json'
import './additionPage.css';
import SwitcherButtons from "./SwitcherButtons";
import TableHead from "./TableHead";
import TableRow from "./TableRow";
// import Filter from './Filter';


const AdditionEquipPage = ({accessToken}) => {

  const [activeColumn, setActiveColumn] = useState(0);
  const totalColumns = 3;
  const [isFilterButtonClicked, setIsFilterButtonClicked] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [filterValue, setFilterValue] = useState('');
  // const [filteredValue, setFilteredValue] = useState('');
  const [isEquipFormOpen, setIsEquipFormOpen] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const categoriesWrapperRef = useRef(null);
  const buttonRef = useRef(null);
  const [equipmentState, setEquipmentState] = useState({
    loading: false,
    equips: null
  });
  const [filters, setFilters] = useState({
    'Гаражный номер': [],
    'VIN': [],
    'Марка': [],
    'Модель': []
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const colorsEquip = {
    'Гаражный номер': '#E5A162',
    'VIN': '#716BC5',
    'Марка': '#F0D164',
    'Модель': '#8869BA',
    'Направление': '#558FA1',
    'Участок': '#708EC9'
  };

  const handlePreviousColumn = () => {
    if(activeColumn > 0) {
      setActiveColumn(activeColumn - 1)
    }
  };

  const handleNextColumn = () => {
    if(activeColumn < totalColumns - 1) {
      setActiveColumn(activeColumn + 1)
    }
  };

  const handleButtonClick = () => {
    setIsEquipFormOpen(!isEquipFormOpen);
    setIsFormVisible(false);
  };

  const handleFiltersButtonClick = () => {
    setIsFilterButtonClicked(!isFilterButtonClicked);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (categoriesWrapperRef.current && !categoriesWrapperRef.current.contains(event.target)  && event.target !== buttonRef.current) {
        setIsFilterButtonClicked(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const openInput = (category) => {
    setCategory(category);
    setCategoryOpen(true);
    setIsFilterButtonClicked(false);
  }

  const handleInputChange = (event) => {
    setFilterValue(event.target.value);
  }

  const handleApplyFilter = () => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [category]: [...prevFilters[category], filterValue]
    }));
    setFilterValue('');
    setCategoryOpen(false);
  };

  // useEffect(() => {
  //   const equipmentData = filteredEquipmentData();
  // }, [filters]);

  const handleFilterDelete = (category, valueToRemove) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [category]: prevFilters[category].filter(value => value !== valueToRemove)
    }));
  };


  const handleTransitionEnd = () => {
    if (isEquipFormOpen) {
      setIsFormVisible(true);
    }
  };

  const fetchEquipmentData = useCallback(() => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_EQUIPMENT}`, {
      method: 'GET',
      headers:{
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log(data)
      setEquipmentState({
          loading: false,
          equips: data
      });
    })
    .catch(error => {
      console.error('Error fetching Excel file:', error);
      setEquipmentState({
        loading:false,
        equips: null
      })
    });
  }, [accessToken]);

  useEffect(() => {
    fetchEquipmentData();
  }, [fetchEquipmentData]);

  const handleFormSubmit = () => {
    fetchEquipmentData();
  };

  const getEquipValue = (equip, category) => {
    switch (category) {
      case 'Гаражный номер':
        return equip.warrantyNumber?.toString() || '';
      case 'VIN':
        return equip.vin || '';
      case 'Марка':
        return equip.brand || '';
      case 'Модель':
        return equip.model || '';
      default:
        return '';
    }
  };

  const filteredEquipmentData = () => {
    if (!equipmentState.equips) return [];

    return equipmentState.equips.filter(equip => {
      return Object.keys(filters).every(category => {
        if (filters[category].length === 0) return true;

        const equipValue = getEquipValue(equip, category).toLowerCase();

        return filters[category].some(filterValue => equipValue.includes(filterValue.toLowerCase()));
      });
    });
  };

  const equipmentData = filteredEquipmentData();

  const onSetCategory = (category) => {
    localStorage.setItem("category", category)
  }

  return (
    <>
      <div className="body-wrapper">
        <div className={isMobile ? 'body-wrapper-mobile' : ''}>
          <div className={isMobile ? 'page-flex-wrapper page-flex-wrapper-mobile' : 'page-flex-wrapper'}>
            <NavigationMenu currentPage='addition' accessToken={accessToken}/>
            <main className={isMobile ? "addition-main-index addition-main-index-mobile" : 'addition-main-index'}>
              <section className="addition-container">
                <section className="addition-inner-container">
                  <div className={isMobile ? "addition-inner-header-wrapper addition-inner-header-wrapper-mobile" : 'addition-inner-header-wrapper'}>
                    <h1>Оборудование</h1>
                    <div className="switcher-container">
                      <SwitcherButtons currentPage='equip'/>
                    </div>
                    {/* <button className="goto-faq-btn" style={{top: '0.2rem', right: '-3rem' }} >
                      <Link
                      onClick={() => onSetCategory('addition')}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 'auto',
                        color: 'black',
                        textDecoration: 'none',
                        width: '35px',
                        height: '35px',
                        borderRadius:'30px'
                        }}
                        to="/faq"
                        state={{ category: "addition" }}
                        >
                        ?
                      </Link>
                    </button> */}
                  </div>

                  <div className={`addition-wrapper ${isEquipFormOpen ? 'collapsed' : ''}`}>
                    <TableHead cells={['Гаражный номер', 'VIN', 'Марка', 'Модель']} activeColumn={activeColumn}/>
                    <div className={isMobile ? 'addition-wrapper__button-wrapper' : 'inactive'}>
                      <button className="addition-wrapper__button-prev" onClick={handlePreviousColumn}></button>
                      <button className="addition-wrapper__button-next" onClick={handleNextColumn}></button>
                    </div>
                    <div className="filter-container">
                      <button className="filter-container__button" ref={buttonRef} onClick={handleFiltersButtonClick}></button>
                      {
                        isFilterButtonClicked &&
                            <div ref={categoriesWrapperRef} className='filter-container__categories-wrapper categories'>
                              <ul className="categories__list">
                                <li className="categories__items">
                                  <button onClick={() => openInput('Гаражный номер')}>Гаражный номер</button>
                                </li>
                                <li className="categories__items">
                                  <button onClick={() => openInput('VIN')}>VIN</button>
                                </li>
                                <li className="categories__items">
                                  <button onClick={() => openInput('Марка')}>Марка</button>
                                </li>
                                <li className="categories__items">
                                  <button onClick={() => openInput('Модель')}>Модель</button>
                                </li>
                              </ul>
                            </div>
                      }
                      {
                        categoryOpen &&
                          <div className="filter-component-wrapper">
                            <div className="filter-category-wrapper" style={{ backgroundColor: colorsEquip[category] }}>
                              <button className="filter-category-wrapper__close-button" type="button" onClick={() => setCategoryOpen(false)}></button>
                              <input
                                className="filter-category-wrapper__input"
                                placeholder={`Введите ${category}`}
                                value={filterValue}
                                onChange={handleInputChange}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    handleApplyFilter();
                                  }
                                }}
                              />
                              <button
                                className="filter-category-wrapper__button"
                                onClick={() => handleApplyFilter()}
                              >
                                Применить
                              </button>
                            </div>
                          </div>
                      }
                      <div className="filter-container__applied-filter-wrapper">
                        {
                          Object.keys(filters).map((category) => (
                            filters[category].length > 0 && (
                              <div key={category} className="filter-container__applied-filter-category">
                                {filters[category].map((value, index) => (
                                  <div className="filter-container__applied-filter" key={index} style={{ backgroundColor: colorsEquip[category] }}>
                                    {value}
                                    <button
                                      className="filter-container__button-delete-filter"
                                      type="button"
                                      onClick={() => handleFilterDelete(category, value)}
                                    ></button>
                                  </div>
                                ))}
                              </div>
                            )
                          ))
                        }
                      </div>
                    </div>

                    <div className="table-row-wrapper">
                    {
                      Array.isArray(equipmentData) && equipmentData.length > 0 ? (
                        equipmentData
                          .filter(equip => equip !== null)
                          .sort((a, b) => {
                            if (a.type?.type < b.type?.type) return -1;
                            if (a.type?.type > b.type?.type) return 1;
                            return 0;
                          })
                          .map((equip, index) => (
                            <TableRow
                              key={index}
                              accessToken={accessToken}
                              data={[`${equip.type?.type || '-'}`, `${equip.warrantyNumber || '-'}`, `${equip.vin || '-'}`, `${equip.brand || '-'}`, `${equip.model || '-'}`, `${equip.mac || '-'}`, equip.id]}
                              page='equip'
                              activeColumn={activeColumn}
                              onFormSubmit={fetchEquipmentData}
                            />
                          ))
                      ) : (
                        <div className="loading-data-block">
                          <p>Equipment data loading</p>
                        </div>
                      )
                    }
                    </div>
                  <div className={`directions-wrapper ${isEquipFormOpen ? 'collapsed' : ''}`}>
                    </div>
                  </div>
                </section>
              </section>
              <button className={`button button-add ${isEquipFormOpen ? 'open' : ''}`} onClick={handleButtonClick} onTransitionEnd={handleTransitionEnd}>+</button>

              {isFormVisible && (
                <>
                  <button className='button-close-form' onClick={handleButtonClick}></button>
                  <EquipForm onFormSubmit={handleFormSubmit} accessToken={accessToken} onClose={handleButtonClick}/>
                </>
              )
              }
            </main>
          </div>
        </div>
      </div>
    </>
  )
};
export default AdditionEquipPage;
