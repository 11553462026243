import './service.css';
import { useState, useRef, useEffect } from 'react';
import apiConfig from "../../apiConfig.json";

const DirectionForm = ({accessToken, onFormSubmit}) => {

  const [directionName, setDirectionName] = useState('');
  const [directorName, setDirectorName] = useState('');

  const [newStaffMember, setNewStaffMember] = useState('');
  const [staffMemberList, setStaffMemberList] = useState([]);
  const [isStaffMemberOpen, setIsStaffMemberOpen] = useState(false);
  const [isAddingStaffMember, setIsAddingStaffMember] = useState(false);
  const [selectedStaffMemberType, setSelectedStaffMemberType] = useState('');

  const [newEqiupment, setNewEqiupment] = useState('');
  const [equipmentList, setEquipmentList] = useState([]);
  const [isEquipmentOpen, setIsEquipmentOpen] = useState(false);
  const [isAddingEquipment, setIsAddingEquipment] = useState(false);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState('');

  const [areas, setAreas] = useState([]);
  const [isAddingArea, setIsAddingArea] = useState(false);
  const [newAreaName, setNewAreaName] = useState('');
  const inputRef = useRef(null);
  const areaInputRef = useRef(null);

  const toggleStaffMemberList = () => {
    setIsStaffMemberOpen(!isStaffMemberOpen);
  };

  const toggleEquipmentList = () => {
    setIsEquipmentOpen(!isEquipmentOpen);
  };

  const handleDirectionNameChange = (event) => {
    setDirectionName(event.target.value);
  };

  const handleDirectorNameChange = (event) => {
    setDirectorName(event.target.value);
  };

  const handleInputStaffMemberChange = (event) => {
    setNewStaffMember(event.target.value);
  };

  const handleInputEquipmentChange = (event) => {
    setNewEqiupment(event.target.value);
  }

  const handleAddStaffMember = () => {
    if (newStaffMember.trim()) {
      setStaffMemberList([...staffMemberList, { name: newStaffMember, role: selectedStaffMemberType }]);
      // console.log(staffMemberList);
      postStaffMember();
      setNewStaffMember('');
      setSelectedStaffMemberType('');
      setIsAddingStaffMember(false);
    }
  };

  // const handleAddStaffMember = () => {
  //   if (newStaffMember.trim()) {
  //     setStaffMemberList([...staffMemberList, newStaffMember]);
  //     console.log(staffMemberList);
  //     setNewStaffMember('');
  //     setIsAddingStaffMember(false);
  //   }
  // };

  const handleAddEquipment = () => {
    if (newEqiupment.trim()) {
      setEquipmentList([...equipmentList, { number: newEqiupment, type: selectedEquipmentType }]);
      postEquipment();
      setNewEqiupment('');
      setSelectedEquipmentType('');
      setIsAddingEquipment(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddStaffMember();
      handleAddEquipment();
    }
  };

  useEffect(() => {
    if (isAddingStaffMember && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingStaffMember]);

  useEffect(() => {
    if (isAddingEquipment && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingEquipment]);

  const removeStaffMember = (index) => {
    const updatedList = staffMemberList.filter((_, i) => i !== index);
    setStaffMemberList(updatedList);
  };

  const removeEquipment = (index) => {
    const updatedList = equipmentList.filter((_, i) => i !== index);
    setEquipmentList(updatedList);
  };

  const handleAreaInputChange = (event) => {
    setNewAreaName(event.target.value);
  };

  const handleAddArea = () => {
    if (newAreaName.trim()) {
      setAreas([...areas, newAreaName]);
      setNewAreaName('');
      setIsAddingArea(false);
    }
  };

  const handleAreaKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddArea();
    }
  };

  useEffect(() => {
    if (isAddingArea && areaInputRef.current) {
      areaInputRef.current.focus();
    }
  }, [isAddingArea]);

  const removeArea = (index) => {
    const updatedList = areas.filter((_, i) => i !== index);
    setAreas(updatedList);
  };

const postEquipment = () => {
    const [id, equipmentType] = [newEqiupment, selectedEquipmentType]
    const equipmentInfo = {
      model: "",
      brand: "",
      vin: "",
      mac: "",
      warrantyNumber: id,
      stateEquipment: "OK",
      lastPlace: "",
      lastShift: "",
      dailyTripCount: 0,
      equipmentType: equipmentType
    };
    console.log('уээээ: ', equipmentType)
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.ADD_EQUIPMENT}`, {
      method: "POST",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(equipmentInfo)
    })
    .then(response => {
      if(response.ok) {
        // onFormSubmit();
        console.log('equipments post worked!');
      }
    })
    .catch(error => {
      console.log(error);
    })
};

const postDirector = (directionId) => {
  const directorInfo = {
    directionId : directionId,
    username : directorName
  };
  fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.ADD_DIRECTOR}`, {
    method: "POST",
    headers: {
      'Authorization' : `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(directorInfo)
  })
  .then(response => {
    if(response.ok) {
      // onFormSubmit();
      console.log('directors post worked!');
    }
  })
  .catch(error => {
    console.log(error);
  })
};

  const postStaffMember = () => {
    const [roleName, username] = [newStaffMember, selectedStaffMemberType];
    console.log(newStaffMember);
    console.log(selectedStaffMemberType);
    const staffMemberIfo = {
      roleName: roleName,
      username: username
    };
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.ADD_STAFF}`, {
      method: "POST",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(staffMemberIfo)
    })
    .then(response => {
      if(response.ok) {
        // onFormSubmit();
        console.log('staffs post worked!');
      }
    })
    .catch(error => {
      console.log(error);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const places = areas.map((area, index) => ({
      name: area,
      directionId: index
    }));
    const organizationId = parseFloat(0);

    postDirector(organizationId);

    const name = directionName;
    const formData = [{organizationId, name, places}];
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_DIRECTIONS}`, {
      method: 'POST',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      if(response.ok) {
        onFormSubmit();
        // console.log('direction data delivered');
      }
    })
    .catch(error => {
      console.log(error);
    })
  };

  const correctStaffRole = (role) => {
    if(role === 'analytics') {return 'аналитики'}
    if (role === 'employees with staff') {return 'работники с персоналом'}
    if (role === 'employees') {return 'работники'}
  };

  return(
    <>
      <section className="direction-form-wrapper">
        <form className='direction-form' onSubmit={handleSubmit} method='post'>
          <label>Название</label>
          <input
          className='service-form-input'
          placeholder='Введите название направления'
          value={directionName}
          onChange={handleDirectionNameChange}
          />
          <label>Руководитель</label>
          <input
          className='service-form-input'
          placeholder='Введите ФИО руководителя'
          value={directorName}
          onChange={handleDirectorNameChange}
          />
          <div className='analytics-item  service-form-input'>
            <p>Сотрудники</p>
            <button type='button' className={'button' && isStaffMemberOpen ? 'button-close-analytics' : 'button-open-analytics'} onClick={toggleStaffMemberList}>
            </button>
          </div>
          <div className={`staff-members-list ${isStaffMemberOpen ? 'open' : ''}`}>
            {staffMemberList.length > 0 && !isAddingStaffMember ? (
              <ul>
                {staffMemberList.map((member, index) => (
                  <li key={index} className='staff-member-list-item'>
                    <span>
                      {member.name}
                    </span>
                    <span>
                      роль: {correctStaffRole(member.role)}
                    </span>
                  <button
                  className="remove-list-item-button"
                  type="button"
                  onClick={() => removeStaffMember(index)}
                >

                </button>
                  </li>
                ))}
              </ul>
            ) : (
                !isAddingStaffMember && (<p>Список сотрудников пуст</p>)
            )}
            {isAddingStaffMember ? (
              <>
                <div className="button-type-list">
                  <button type='button' className="button-add-staff-member-type button-add-member-analytic" onClick={() => setSelectedStaffMemberType('analytics')}>Аналитики</button>
                  <button type='button' className="button-add-staff-member-type button-add-member-personal" onClick={() => setSelectedStaffMemberType('employees with staff')}>Работники с персоналом</button>
                  <button type='button' className="button-add-staff-member-type button-add-member-worker" onClick={() => setSelectedStaffMemberType('employees')}>Работники</button>
                </div>
                <input
                  ref={inputRef}
                  className='service-form-input service-form-input-list'
                  type='text'
                  value={newStaffMember}
                  onChange={handleInputStaffMemberChange}
                  onKeyDown={handleKeyPress}
                  placeholder='Введите ФИО'
                />
                <button
                  className='button-add-list-item'
                  type='button'
                  onClick={handleAddStaffMember}
                >
                  Добавить
                </button>
              </>
              ) : (
                <button
                  className='button-add-list-item'
                  type='button'
                  onClick={() => setIsAddingStaffMember(true)}
                >
                  Добавить сотрудника
                </button>
            )}
          </div>
          <div className='equipment-item  areas-form-input'>
            <p>Оборудование</p>
            <button type='button' className={'button' && isEquipmentOpen ? 'button-close-analytics' : 'button-open-analytics'} onClick={toggleEquipmentList}>
            </button>
          </div>
          <div className={`equipment-list ${isEquipmentOpen ? 'open' : ''}`}>
            {equipmentList.length > 0 && !isAddingEquipment ? (
                <ul>
                  {equipmentList.map((equipment, index) => (
                    <li key={index} className='equipment-list-item'>
                      <span className={`added-equipment-icon ${equipment.type}`}></span>
                      {equipment.number}
                    <button
                    className="remove-list-item-button"
                    type="button"
                    onClick={() => removeEquipment(index)}
                  >
                  </button>
                    </li>
                  ))}
                </ul>
              ) : (
                !isAddingEquipment &&
                <p>Список оборудования пуст</p>
            )}
            {isAddingEquipment ? (
              <>
              <div className="button-type-list">
                <button type='button' className="button-add-equipment technic-type-white-excavator" onClick={() => setSelectedEquipmentType('technic-type-black-excavator')}></button>
                <button type='button' className="button-add-equipment technic-type-white-bulldozer" onClick={() => setSelectedEquipmentType('technic-type-black-bulldozer')}></button>
                <button type='button' className="button-add-equipment technic-type-white-crane" onClick={() => setSelectedEquipmentType('technic-type-black-crane')}></button>
                <button type='button' className="button-add-equipment technic-type-white-grader" onClick={() => setSelectedEquipmentType('technic-type-black-grader')}></button>
                <button type='button' className="button-add-equipment technic-type-white-rink" onClick={() => setSelectedEquipmentType('technic-type-black-rink')}></button>
                <button type='button' className="button-add-equipment technic-type-white-truck" onClick={() => setSelectedEquipmentType('technic-type-black-truck')}></button>
                <button type='button' className="button-add-equipment technic-type-white-auto" onClick={() => setSelectedEquipmentType('technic-type-black-auto')}></button>
              </div>
                <input
                  ref={inputRef}
                  className='service-form-input service-form-input-list'
                  type='text'
                  value={newEqiupment}
                  onChange={handleInputEquipmentChange}
                  onKeyDown={handleKeyPress}
                  placeholder='Введите гаражный номер'
                />
                <button
                  className='button-add-list-item'
                  type='button'
                  onClick={handleAddEquipment}
                >
                  Добавить
                </button>
              </>
              ) : (
                <button
                  className='button-add-list-item'
                  type='button'
                  onClick={() => setIsAddingEquipment(true)}
                >
                  Добавить оборудование
                </button>
            )}
          </div>
          <label>Участки</label>
          <div className="areas-list">
            {areas.map((area, index) => (
              <div key={index} className="area-item">
                {area}
                <button
                    className="remove-list-item-button"
                    type="button"
                    onClick={() => removeArea(index)}
                  >
                  </button>
              </div>
            ))}
          </div>
          {isAddingArea ? (
            <>
            <div className='area-input'>
              <input
                ref={areaInputRef}
                className='service-form-input'
                type='text'
                value={newAreaName}
                onChange={handleAreaInputChange}
                onKeyDown={handleAreaKeyPress}
              />
              <button
                className='button-add-area new-button-yellow '
                type='button'
                onClick={handleAddArea}
              >
              </button>
            </div>
            </>
          ) : (
            <button
              className='button-add-area new-button-yellow'
              type='button'
              onClick={() => setIsAddingArea(true)}
            >
            </button>
          )}
          <button className='button-submit-direction' type='submit'>Добавить направление</button>
        </form>
      </section>
    </>
  )
}

export default DirectionForm;
