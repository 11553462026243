import { useEffect, useState, useRef } from "react";
import apiConfig from "../../../apiConfig.json";

const VinPopUp = ({show, onClose, top, left, vin, model, warrantyNumber, technicType, imagesArray, toggleGalleryPopUp}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const refVinPopup = useRef(null);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleCloseVinPopup = (e) => {
    if(refVinPopup.current && !refVinPopup.current.contains(e.target)){
    onClose()
    }
  }

  const getImageForEquipmentType = (technicType) => {
    switch (technicType) {
      case 'EXCAVATOR':
        return require('../../../Images/Equipment/excavator_32.png');
      case 'BULLDOZER':
        return require('../../../Images/Equipment/bulldozer_32.png');
      case 'CRANE':
        return require('../../../Images/Equipment/crane_32.png');
      case 'GRADER':
        return require('../../../Images/Equipment/grader_32.png');
      case 'RINC':
        return require('../../../Images/Equipment/rinc_32.png');
      case 'RINK':
        return require('../../../Images/Equipment/rink_32.png');
      case 'TRUCK':
        return require('../../../Images/Equipment/truck_32.png');
      case 'POOKER':
        return require('../../../Images/Equipment/camera_10.png');
      case 'AUTO':
        return require('../../../Images/Equipment/auto_32.png');
      default:
        return require('../../../Images/Equipment/excavator_32.png');
    }
  };

  if(!show) return null;

  return(
    <>
      <div className="vin-pop-up-wrapper" onClick={(e) => handleCloseVinPopup(e)}>
      <section ref={refVinPopup} className={isMobile ? 'inactive' : 'vin-pop-up-desktop'} style={{position: 'absolute', top: `${top}px`, left: `${left}px`}}>
        <ul className="vin-desktop-list">
          <li className="vin-desktop-item">
            <img src={getImageForEquipmentType(technicType)} width={65} height={60} alt="car type"/>
          </li>
          <li className="vin-desktop-item">
            <p>VIN</p>
            <p>{vin}</p>
          </li>
          <li className="vin-desktop-item">
            <p>Гаражный номер</p>
            <p>{warrantyNumber}</p>
          </li>
          <li className="vin-desktop-item">
            <p>Модель</p>
            <p>{model}</p>
          </li>
          <li className="vin-desktop-item">
            <p>Тип техники</p>
            <p>{technicType}</p>
          </li>
          <li className="vin-desktop-item">
            <ul className="vin-images-list">
              {
                 imagesArray.map((image, index) => {
                  const img = `${apiConfig.BASE_URL}` + image.value;
                  return(
                    <li className="vin-images-item" key={index}>
                      <button className="vin-images-button" onClick={() => toggleGalleryPopUp(imagesArray, img)}>
                        <img src={img} width={65} height={60} alt="shift"/>
                      </button>
                    </li>
                  )
                })
              }
              </ul>
          </li>
        </ul>
      </section>
      </div>
    </>
  );
};

export default VinPopUp;
