import { useCallback, useEffect, useRef, useState } from "react";
import apiConfig from "../../apiConfig.json";
import '../../App.css'

const LoadExcelFile = (props) => {

  const [excelFile, setExcelFile] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const accessToken = props.accessToken;
  const shiftsDataExcel = props.shiftDataExcel;
  // console.log(shiftsDataExcel)

  const fetchExcelFile = useCallback(() => {
    setDownloadStatus('downloading');

    const shiftDataExcel = [
      {
        "end": "2024-09-09T18:23:59.482",
        "id": 123,
        "login": "Admin Adminovich Adminov",
        "start": "2024-09-09T18:23:30.482",
        "trips": [
          {
            "tripEnd": "2024-09-09T18:23:57.482",
            "tripId": 123,
            "tripRoute": {
              "2024-09-09T18:23:34.482": "60.0547584, 30.4169640",
              "2024-09-09T18:23:50.482": "60.0547554, 30.4169622"
            },
            "tripStart": "2024-09-09T18:23:32.482"
          }
        ],
        "route": {
          "2024-09-09T18:23:34.482": "60.0547584, 30.4169640",
          "2024-09-09T18:23:50.482": "60.0547554, 30.4169622"
        }
      }
    ]

    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_EXCEL_SHIFTS}`, {
      method: 'POST',
      headers:{
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(shiftsDataExcel),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      setExcelFile(blob);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute("download", "report.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadStatus('downloaded');
    })
    .catch(error => {
      console.error('Error fetching Excel file:', error);
      setDownloadStatus('error');
    });
  }, [accessToken]);


  const handleDownloadExcel = () => {
    fetchExcelFile();
  }

  return(
    <>
    <div className="download-excel">
      <button className="download-excel__button" onClick={handleDownloadExcel}>Скачать отчёт</button>
      {downloadStatus === "downloading" && <p className="download-excel__message">Загрузка файла...</p>}
      {downloadStatus === "downloaded" && <p className="download-excel__message">Файл загружен</p>}
      {downloadStatus === "error" && <p className="download-excel__message">Ошибка при загрузке файла.</p>}
    </div>
    </>
  )
}

export default LoadExcelFile;
