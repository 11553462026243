import './modalShiftTable.css'

const CustomTooltip = ({data, position, averageValue}) => {
  // console.log(data)
  return(
    <>
    <div
      className="custom-tooltip"
      style={{
        position: 'absolute',
        zIndex: '20',
        top: '-50px',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '25px',
        backgroundColor: '#211B27',
        color: '#ffffff',
        borderRadius: '15px',
        pointerEvents: 'none'
      }}
    >
    {data.date !== null && data.value !== 0 ? (
        <>
        {
          data.value <= averageValue * 2 && <p>Уровень продуктивности ниже среднего</p>
        }
        {
          averageValue * 2 < data.value && data.value <= averageValue * 3 && <p>Уровень продуктивности средний</p>
        }
        {
          averageValue * 3 < data.value && data.value <= averageValue * 4 && <p>Уровень продуктивности выше среднего</p>
        }
        {
          data.value > averageValue * 4 && <p>Уровень продуктивности высокий</p>
        }
        <div className="custom-tooltip__info">
          <div>
            <p>Дата: {`${data.date}`}</p>
          <div>
          </div>
            <p>Кол-во рейсов: {`${data.value}`}</p>
          </div>
        </div>
        </>
      ) : (
        <>
          <p>Уровень продуктивности низкий</p>
          <p>0 peйсов</p>
        </>
      )
    }
  </div>
    </>
  )
}

export default CustomTooltip;