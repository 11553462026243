import  { Link } from 'react-router-dom'

const GotoFAQButton = (props) => {
  const {category = 'table', ...style} = props
  return (
    <button className="goto-faq-btn"style={style} >
      <Link
      onClick={() => localStorage.setItem("category", category)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        color: 'black',
        textDecoration: 'none',
        width: '100%',
        height: '100%',
        borderRadius:'30px'
        }}
        to="/faq"
        state={{ category: category }}
        >
        ?
      </Link>
    </button>
  )
}

export default GotoFAQButton