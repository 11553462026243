import React from 'react';
import { useState, useEffect } from 'react';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handlePageChange = (page) => {
    onPageChange(page - 1);
  }

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxButtonsToShow = 2;
    const maxAdjacentButtons = 1;

    if (totalPages <= maxButtonsToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage > maxAdjacentButtons + 1) {
        pageNumbers.push(1);
        if (currentPage > maxAdjacentButtons + 2) {
          pageNumbers.push('...');
        }
      }

      const start = Math.max(1, currentPage - maxAdjacentButtons);
      const end = Math.min(totalPages, currentPage + maxAdjacentButtons);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - maxAdjacentButtons) {
        if (currentPage < totalPages - maxAdjacentButtons - 1) {
          pageNumbers.push('...');
        }
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((number, index) =>
      number === '...' ? (
        <span key={index} className="dots">
          {number}
        </span>
      ) : (
        <button
          key={index}
          className={number === currentPage + 1 ? isMobile ? 'pagination-button-mobile active-page' : 'pagination-button active-page' : isMobile ? 'pagination-button-mobile' : 'pagination-button'}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </button>
      )
    );
  };

  return (
    <div className="pagination">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 0}
        className={isMobile ? 'pagination-mobile-button-back' : 'button-back pagination-back-forth-buttons'}
      ></button>
      {renderPageNumbers()}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={isMobile ? 'pagination-mobile-button-forth' : 'button-forth pagination-back-forth-buttons'}
      ></button>
  </div>
  );
};

export default Pagination;
