import HeaderChart from './HeaderChart.jsx';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <>
          <div className="tracker-header__wrapper">
            <div className="tracker-header__item tracker-header__gant-chart">
              <HeaderChart />
              <Link to="/gantt-chart"> 
               <button className="tracker-goto-button tracker-goto-arrow-button rotated-right-bottom"></button>
              </Link>
            </div>

            <div className="tracker-header__item tracker-header__diagram">
            <button className="tracker-goto-button tracker-goto-arrow-button rotated-right-bottom"></button>
            </div>

            <div className="tracker-header__item tracker-header__my-tasks">
            <button className="tracker-goto-button tracker-goto-arrow-button rotated-right-bottom"></button>
            </div>
          </div>
        </>
    )
};

export default Header; 