import { useEffect } from 'react';
import './modals.css';

const ModalDelete = (props) => {
  const { message, actionText, action, onClose } = props;

  useEffect(() => {
    const handleCloseFilterByEscape = (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    };
    document.body.addEventListener('keydown', handleCloseFilterByEscape);

    return () => {
      document.body.removeEventListener('keydown', handleCloseFilterByEscape);
    };
  }, [onClose]);

  return(
    <>
      <div className="modal-window modal-window_delete">
        <div className="modal-window__wrapper modal-window_delete__wrapper">
          <p>{message}</p>
          <section className='modal-window__buttons-block modal-window_delete__buttons-block'>
            <button className='modal-window__button_delete button_reverse_deletion' onClick={() => onClose()}> Отменить </button>
            <button className='modal-window__button_delete button_deletion' onClick={() => action() }> {actionText} </button>
          </section>
        </div>
      </div>
    </>
  )
}
export default ModalDelete;
