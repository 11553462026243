import Chart from "react-apexcharts";
import { useState, useEffect } from 'react';

const GraphPopup = ({show, onClose, voltage}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const voltageKeys = Object.keys(voltage);
  const categories = [];
  const data = [];

  if(voltage) {
    voltageKeys.forEach((key, index) => {
        const splitKey = key.split('T');
        if(splitKey.length >= 2) {
          const time = key.split('T')[1].slice(0, 5);
          categories.push(time);
          data.push(voltage[key]);

          if(index > 0) {
            categories.push(time);
            data.push(voltage[key]);
          } else if (index === 0){
            data.push(voltage[key]);
          }
        }
      }
    )
  }

  // console.log(data);

  const options = {
    chart: {
      id: "basic-line",
      type: 'line',
      background: 'white'
    },
    stroke: {
      width: 2,
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: 0
      }
    }
  };

  const series = [
    {
      name: "напряжение",
      data: data,
      color: '#FCBA03',
      strokeWidth: 2
    }
  ];

  if(!show) return null;

  return(
    <>
      <section className={isMobile ? 'overlay-darker modal-container' : 'overlay-shift overlay-darker modal-container'}>
        <div className={isMobile ? "modal" : "chart-modal"}>
          <button className={isMobile ? 'modal-button mobile-modal-button' : "modal-button"} onClick={onClose}></button>
                {isMobile ? (
                  <div className="mixed-chart-mobile modal">
                    <Chart
                    options={options}
                    series={series}
                    type="line"
                    width="600"
                  />
                </div>
                ) : (
                  <div className="mixed-chart modal">
                    <Chart
                    options={options}
                    series={series}
                    type="line"
                    width="1000"
                  />
                </div>
                )}
        </div>
      </section>
    </>
  )
}

export default GraphPopup;



    // annotations: {
    //   xaxis: [
    //     {
    //       x: 0,
    //       strokeDashArray: 5,
    //       borderColor: '#000',
    //       label: {
    //         borderColor: '#000',
    //         style: {
    //           color: '#000',
    //           background: '#fff',
    //         },
    //         text: 'Рейс 1'
    //       }
    //     }
    // }
